import {uiTypes} from './uiTypes';

export const uiCloseAlert = () => ({
    type: uiTypes.uiCloseAlert
})

export const uiShowAlert = (severity, title, message) => ({
    type: uiTypes.uiOpenAlert,
    payload: {severity, title, message}
})

export const uiShowCreateSellOrder = () => ({
    type: uiTypes.uiOpenCreateSellOrderDialog
})

export const uiCloseCreateSellOrder = () => ({
    type: uiTypes.uiCloseCreateSellOrderDialog
})

export const uiShowCreateBuyOrder = () => ({
    type: uiTypes.uiOpenCreateBuyOrderDialog
})

export const uiCloseCreateBuyOrder = () => ({
    type: uiTypes.uiCloseCreateBuyOrderDialog
})

export const uiShowBackdrop = () => ({
    type: uiTypes.uiOpenBackdrop
})

export const uiCloseBackdrop = () => ({
    type: uiTypes.uiCloseBackdrop
})

export const uiShowItemDetail = (tokenId) => ({
    type: uiTypes.uiShowItemDetail,
    payload: tokenId
})

export const uiCloseItemDetail = () => ({
    type: uiTypes.uiCloseItemDetail
})

export const setMarketplaceFilter = (filter) => ({
    type: uiTypes.uiSetMarketFilter,
    payload: filter
})