import {discountTypes} from './discountTypes';

const initialState = {
    isReferralCodeEnabled: true,
    activePhase: null,
    discount: {description: null, percentage: 0},
    noDiscountTotal: 0,
    discountTotal:{usd: 0, eth:0},
    myPhase: null,
    calculating: true,
}

export const discountReducer = (state = initialState, action) => {
    switch (action.type) {
        // sets the provider, network and address once we connected with the wallet
        case discountTypes.setIsReferralCodeEnabled:
            return {
                ...state,
                isReferralCodeEnabled: action.payload
            }

        case discountTypes.setDiscount:
            return {
                ...state,
                discount: action.payload
            }
        
        case discountTypes.setNoDiscountTotal:
            return {
                ...state,
                noDiscountTotal: action.payload
            }
        
        case discountTypes.setDiscountTotal:
            return {
                ...state,
                discountTotal: action.payload
            }
        
        case discountTypes.setActivePhase:
            return {
                ...state,
                activePhase: action.payload
            }

        case discountTypes.setMyPhase:
            return{
                ...state,
                myPhase: action.payload
            }

        case discountTypes.setCalculating:
            return {
                ...state,
                calculating: action.payload
            }
        default:
            return state
    }
}