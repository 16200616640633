import { Box, Button, Tooltip } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { showWalletProviderModal } from 'reducers/walletProviderReducer/walletProviderActions'

export const WalletAuth = () => {
  const dispatch = useDispatch()

  const handleWalletConnect = () => {
    dispatch(showWalletProviderModal(true));
  }

  return (
      <Tooltip
        title='For web3 users!'
        arrow
      >
        <Button 
            variant='contained' 
            fullWidth
            size='large'
            color='info'
            onClick={handleWalletConnect}
        >
            Connect your wallet
        </Button>
    </Tooltip>
  )
}
