import { db } from "config/firebase";
import { adminTypes } from "./adminTypes"
import { uiShowAlert } from "reducers/uiReducer/uiActions";
import { logError } from "utils/errors";

export const verifiyIsAdmin = () => {
    return async (dispatch, getState) => {
        try {
            const {uid} = getState().auth;
            const snapshot = await db.collection('admin').doc('admins').get();
            if (!snapshot.exists)
                return dispatch(setIsAdmin(false));

            const doc = snapshot.data();
            const exists = doc.adminList.find(val => val === uid);
            if (exists)
                dispatch(setIsAdmin(true))
            else
                dispatch(setIsAdmin(true))
        } catch ( error ) {
            dispatch(setIsAdmin(false));
        }
    }
}

export const getBatchMint = () => {
    return async (dispatch) => {
        try {
            const list = []
            const snapshot = await db.collection('admin').doc('stats').collection('batchMint').get();
            for await (const doc of snapshot.docs){
                const data = doc.data();
                list.push(data)
            }
            
            dispatch(setBatchMintList(list))
        } catch (error) {
            logError('Batch mint', error, null)
            dispatch(uiShowAlert('error', 'Admin', 'There was an error retrieving the list of batch minting'));
        }
    }
}

const setBatchMintList = (list) => ({
    type: adminTypes.setBatchMintList,
    payload: list
})

const setIsAdmin = (isAdmin) => ({
    type: adminTypes.setIsAdmin,
    payload: isAdmin
})

const setSalesList = (sales) => ({
    type: adminTypes.setSalesList,
    payload: sales
})
const setUsersList = (users) => ({
    type: adminTypes.setUsersList,
    payload: users
})
const setMoneyList = (money) => ({
    type: adminTypes.setMoneyList,
    payload: money
})
const setInProgressList = (inProgress) => ({
    type: adminTypes.setInProgressList,
    payload: inProgress
})

export const getSales = () => {
    return async (dispatch) => {
        const sales = [];
        const money = [];
        const users = []
        try {
            const snapshot = await db.collection('admin').doc('stats').collection('sales').get();
            
            if (snapshot.empty){
                dispatch(setSalesList(sales))
                dispatch(setMoneyList(money))
                dispatch(setUsersList(users))
                return;
            }


            for await (const doc of snapshot.docs){
                const data = doc.data();
                const {user, order, oneOfOnes, wearables} = data;
                const {address, email, aquiredOneOfOnes, uid} = user;
                users.push({uid, address, email, aquiredOneOfOnes});
                sales.push({user, order, oneOfOnes, wearables})
                money.push({user, order})
            }
                
            dispatch(setSalesList(sales));

            const uniqueUsers = getUniqueListBy(users, 'uid');
            dispatch(setUsersList(uniqueUsers));
            dispatch(setMoneyList(money));

        } catch (error) {
            logError('getSales', error, null)
            dispatch(uiShowAlert('warning', 'Statistics', 'There was an error getting statistics. Please check your connection and try again.'));
        }
    }
}

function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}

export const setArtEnabled = async (artId, enabled) => {
    try {
        await db.collection('drop1').doc('art').collection('metadata').doc(artId.toString()).set({enabled}, {merge:true});
    } catch (error) {
        await logError('setArtEnabled', error)
        throw error;
    }
}

export const SavePrice = async (artId, price) => {
    try {
        await db.collection('drop1').doc('art').collection('metadata').doc(artId.toString()).set({price}, {merge:true});
    } catch (error) {
        logError('SavePrice', error)
        throw error;
    }
}

export const loadInProgress = () => {
    return async (dispatch) => {
        try {
            const inProgress = [];
            const snapshot = await db.collection('admin').doc('stats').collection('inProgress').get();
            for (const doc of snapshot.docs){
                inProgress.push(doc.data());
            }

            dispatch(setInProgressList(inProgress.sort(sortInProgress)))
        } catch (error) {
            logError('loadInProgress', error);
            dispatch(uiShowAlert('error', 'In Progress', 'We are unable to load in progress list. Verify your connection and try again.'));
        }
    }
}

const sortInProgress = (a,b) => {
    if (a.oneOfOnes.length > b.oneOfOnes.length)
        return -1
    if (a.oneOfOnes.length < b.oneOfOnes.length)
        return 1
    return 0;
}

const setNoMintList = (noMint) => ({
    type: adminTypes.setNoMintList,
    payload: noMint
})

export const loadNoMint = () => {
    return async (dispatch) => {
        try {
            const result = []
            const snapshot = await db.collection('admin').doc('stats').collection('noMint').get();
            for await (const doc of snapshot.docs){
                const data = doc.data();
                result.push(data);
            }

            dispatch(setNoMintList(result));
        } catch (error) {
            logError('loadNoMint', error)
            dispatch(uiShowAlert('error', 'No Mint', 'We are unable to load no mint list. Verify your connection and try again.'));
        }
    }
}

const setLastUpdated = (lastUpdated) => ({
    type: adminTypes.setLastUpdated,
    payload: lastUpdated
})

export const getLastUpdated = () => {
    return async (dispatch) => {
        try {
            const snapshot = await db.collection('admin').doc('stats').get();
            if (!snapshot.exists)
                return;

            const data = snapshot.data();
            dispatch(setLastUpdated(data.lastUpdate));
        } catch (error) {
            logError('getLastUpdated', error)
        }
    }
}