export const getFormatedPrice = (stripePrice) => {
  return (stripePrice / 100).toFixed(2);
};

export const toStripePrice = (formatedPrice) => {
  return formatedPrice * 100;
};

export const getDiscountedPrice = (price) => {
  return price;
};
