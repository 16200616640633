import { Grid, Typography } from '@mui/material'
import React from 'react'
import { PhysicalTable } from './components/PhysicalTable'

export const AdminPhysicals = () => {
  return (
    <Grid container direction={'column'} alignItems={'center'} spacing={2} justifyContent={'center'}>
        <Grid item>
            <Typography variant='h5'>
                Physical sneaks status
            </Typography>

            <Typography variant='body'>
                Export and send to China manufacturer the details
            </Typography>

            <Grid item>
                <PhysicalTable/>
            </Grid>
        </Grid>
    </Grid>
  )
}
