import { logError } from "./errors";

const apiToken = process.env.REACT_APP_API_TOKEN;
const apiURL = process.env.REACT_APP_API_URL;

export const requestAuthMessage = async (address) => {
    const data = {address};
    const url = apiURL.concat('auth/getMessage');    
    const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiToken
        },
        body: JSON.stringify(data)
      });
      return response.json(); 
}

export const requestAuthToken = async (address, signature) => {
    const data = {address, signature};
    const url = apiURL.concat('auth/getToken');    
    
    const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiToken
        },
        body: JSON.stringify(data)
      });
      return response.json(); 
}

export const apiRequestPaymentIntent = async (items) => {
  const data = {items};
    const url = apiURL.concat('payment/create-payment-intent');    
    
    const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiToken
        },
        body: JSON.stringify(data)
      });
      
      const value = await response.json();
      
      if (!value.ok){
        await logError('apiRequestPaymentIntent', value, null)
      }

      
      return value; 

}

export const apiGetCurrentETHPrice = async () => {
  const defaultValue = 1510.95;

  try {
    const apiKey = process.env.REACT_APP_BLOCKCHAIN_ETHERSCAN_API;
    const url = `https://api.etherscan.io/api?module=stats&action=ethprice&apikey=${apiKey}`
    const response = await fetch(url);
    const message = await response.json()
    if (message.status === '1'){
      return Number(message.result.ethusd)
    } else {
      return defaultValue;
    }

  } catch ( error ){
    logError('apiGetCurrentETHPrice', error, null)      
    return defaultValue;
  }

}

export const createShopifyCustomer = async (customer) => {
  const data = {customer};
  const url = apiURL.concat('shopify/customer');    
  
  const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiToken
      },
      body: JSON.stringify(data)
    });
    
    const value = await response.json();
    
    if (!value.ok){
      await logError('createShopifyCustomer', value, null)
    }

    
    return value; 
}

export const getcustomerByEmail = async (email) => {
  const data = {email};
  const url = apiURL.concat('shopify/customer');    
  
  const response = await fetch(url + '?' + new URLSearchParams(data), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiToken
      }
    });
    
    const value = await response.json();
    
    if (!value.ok){
      await logError('getcustomerByEmail', value, null)
    }

    
    return value; 
}

export const createShopifyProduct = async (product) => {
  const data = {product};
  const url = apiURL.concat('shopify/product');    
  
  const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiToken
      },
      body: JSON.stringify(data)
    });
    
    const value = await response.json();
    
    if (!value.ok){
      await logError('createShopifyProduct', value, null)
    }

    
    return value; 
}

export const getProductById = async (id) => {
  const data = {id};
  const url = apiURL.concat('shopify/product');    
  
  const response = await fetch(url + '?' + new URLSearchParams(data), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiToken
      }
    });
    
    const value = await response.json();
    
    if (!value.ok){
      await logError('getProductById', value, null)
    }
    
    return value; 
}

export const getOrderById = async (id) => {
  const data = {id};
  const url = apiURL.concat('shopify/order');    
  
  const response = await fetch(url + '?' + new URLSearchParams(data), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiToken
      }
    });
    
    const value = await response.json();
    
    if (!value.ok){
      await logError('getOrderById', value, null)
    }
    
    return value; 
}

export const createShopifyOrder = async (order) => {
  const data = {order};
  const url = apiURL.concat('shopify/order');    
  
  const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiToken
      },
      body: JSON.stringify(data)
    });
    
    const value = await response.json();
    
    if (!value.ok){
      await logError('createShopifyOrder', value, null)
    }

    
    return value; 
}
