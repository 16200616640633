import { EmailOutlined, Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import React, {useEffect, useState} from 'react'
import PasswordStrengthBar from 'react-password-strength-bar'
import { useDispatch } from 'react-redux'
import { authStartSignUpEmailPassword } from 'reducers/authReducer/authActions'
import { validateEmail } from 'utils/email'

export const EmailPassAuth = () => {
  const [formData, setFormData] = useState({email: '', password: '', passwordValidation: ''})
  const [score, setScore] = useState(0)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [helperTexts, setHelperTexts] = useState({email: '', password: '', passwordValidation: ''})
  const [showPassword, setShowPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  
  const dispatch = useDispatch();
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (e) => {e.preventDefault();};

  const handleEmailChange = (e) => {
    e.preventDefault();
    setFormData({...formData, email: e.target.value})
  }
  const handlePasswordChange = (e) => {
    e.preventDefault();
    setFormData({...formData, password: e.target.value})
  }
  const handlePasswordValidationChange = (e) => {
    e.preventDefault();
    setFormData({...formData, passwordValidation: e.target.value})
  }

  const validateForm = () => {
    // we validate the email
    if (validateEmail(formData.email))
      setIsEmailValid(true)
    else
      setIsEmailValid(false)


    // we validate password validation
    if (formData.password === formData.passwordValidation)
      setPasswordMatch(true)
    else
      setPasswordMatch(false)

  }

  useEffect(() => {
    validateForm()    
  }, [formData])



  const handleSubmit = () => {
    let withError = false;

    if (!passwordMatch) {
      setHelperTexts({...helperTexts, passwordValidation: 'Incorrect password'});
      withError = true
    }
    else
      setHelperTexts({...helperTexts, passwordValidation: ''});

    if (!isEmailValid){
      setHelperTexts({...helperTexts, email: 'Email is not valid'});
      withError = true;
    }
    else
      setHelperTexts({...helperTexts, email: ''});


    if (score < 2){
      setHelperTexts({...helperTexts, password: `Not strong  enought`});
      withError = true
    } else 
      setHelperTexts({...helperTexts, password: ''});

      if (!withError)
        dispatch(authStartSignUpEmailPassword(formData.email, formData.password));
    
  }
  

  return (
    <Grid
      container
      direction={'column'}
      alignItems='center'
      justifyContent={'center'}
      spacing={2}
    >
        <Grid item>
          <FormControl variant="outlined" fullWidth>
            <TextField
            inputProps={{
              autoComplete: 'off'
            }}
              type='text'
              fullWidth
              autoFocus={true}
              required
              autoComplete={'off'}
              value={formData.email}
              onChange={handleEmailChange}
              error={(isEmailValid) ? false : true}
              label="Email"
              id="outlined-start-adornment"
              sx={{ m: 1, width: '25ch' }}
              InputProps={{
                startAdornment: <InputAdornment position="start"><EmailOutlined/></InputAdornment>,
              }}
            />
            <FormHelperText error>
              {helperTexts.email}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              required
              fullWidth
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handlePasswordChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            <FormHelperText error>
              {helperTexts.password}
            </FormHelperText>
          </FormControl>
          <PasswordStrengthBar password={formData.password} onChangeScore={(passScore) => setScore(passScore)}/>
        </Grid>

        <Grid item>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">Re type your password</InputLabel>
            <OutlinedInput
              fullWidth
              error = {(passwordMatch) ? false : true}
              required
              autoComplete="new-password"
              type={showPassword ? 'text' : 'password'}
              value={formData.passwordValidation}
              onChange={handlePasswordValidationChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Re type your password"
            />
            <FormHelperText error >
              {helperTexts.passwordValidation}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item width={'100%'}>
          <Button fullWidth variant='contained' color={'info'} onClick={handleSubmit}>REGISTER</Button>
        </Grid>
        
    </Grid>
  )
}
