import React from 'react'
import { useSelector } from 'react-redux'
import { SignInMenu } from './components/SignInMenu/SignInMenu';
import { LoggedMenu } from './components/LoggedMenu';

export const UserMenu = () => {
    const {uid} = useSelector(state => state.auth);

  return (
    <div>
        {(uid) ? (
            <LoggedMenu/>
        ) : (
            <SignInMenu/>
        )}
    </div>
  )
}
