import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTraits, searchArt } from 'reducers/traitsReducer/traitsActions';
import { TraitFilters } from './components/TraitFilters';
import { Box, Button, CircularProgress, Grid, Pagination } from '@mui/material';
import { AdminArtCard } from './components/AdminArtCard';

export const AdminArt = () => {
  const dispatch = useDispatch();
  const {selectedFormulas, selectedSkins, selectedLaces, selectedTrims, artList, firstDoc, lastDoc} = useSelector(state => state.traits);

  useEffect(() => {
    dispatch(getTraits())
    dispatch(searchArt(true, true))
  }, [])

  useEffect(() => {
    dispatch(searchArt(true, true))
  }, [selectedFormulas, selectedSkins, selectedLaces, selectedTrims])
  
  const handleNext = () => {
    dispatch(searchArt(true, true))
  }
  
  const handlePrevious = () => {
    dispatch(searchArt(false, true))
  }

  return (
    <Grid container direction={'row'} spacing={2}>
      <Grid item xs={12} md={3}>
        <TraitFilters/>
      </Grid>

      <Grid item xs={12} md={9}>
        {(artList.length == 0) ? (
          <Grid item xs={6} md={4}>
            <CircularProgress/>
          </Grid>
        ) : (
          <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'}>
            <Grid item >
              <Button onClick={handlePrevious} disabled={(!firstDoc)}>Previous</Button>
              <Button onClick={handleNext}  disabled={(!lastDoc)}>Next</Button>
            </Grid>

            <Grid item >
              <Grid container direction={'row'} spacing={2}>
                {artList.map((val, index) => (
                  <Grid item xs={6} md={4} key={index}>
                    <AdminArtCard item={val}/>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
