import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Switch, TextField, Typography } from '@mui/material'
import { getFormatedPrice, toStripePrice } from 'utils/price';
import { useDispatch } from 'react-redux';
import { uiShowAlert } from 'reducers/uiReducer/uiActions';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import { SavePrice, setArtEnabled } from 'reducers/AdminReducer/adminActions';
import { AnimationDialog } from 'common/AnimationDialog';
import gif from 'images/marketplace/oneOfOneLoading.gif'

export const AdminArtCard = (props) => {
    const {item} = props;
    const [isDisabled, setIsDisabled] = useState(true)
    const [checked, setChecked] = useState(item.enabled)
    const [price, setPrice] = useState(item.price)
    const [openAnimation, setOpenAnimation] = useState(false)
    const dispatch = useDispatch();
    const handleAnimationClick = () => {
        setOpenAnimation(true);
    }
    
    const handleSavePrice = async () => {
        try {
            await SavePrice(item.artId, toStripePrice(price))
            dispatch(uiShowAlert('success', 'Enabled', 'Saved successfully'))
            setIsDisabled(true)
        } catch (error) {
            dispatch(uiShowAlert('error', 'Price change', 'There was an error saving your price change, Verify your connection and try again.'))
        }
    }
    
    const handleChange = async (e) => {
        setChecked(e.target.checked)
        try{
            await setArtEnabled(item.artId, !checked)    
            dispatch(uiShowAlert('success', 'Enabled', 'Saved successfully'))
        } catch ( error ){
            dispatch(uiShowAlert('error', 'Enabled', 'There was a problem setting the enabled property. Check your connection and try again.'));
        }
    }

    const handlePriceChange = (e) => {
        const {value } = e.target;
        if (isNaN(value)){
            setIsDisabled(true)
            return;
        }

        setPrice(value)
        setIsDisabled(false)
    }

  return (
    <Card elevation={2}>
        <CardHeader
            title={
                <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        <FormControlLabel control={<Switch checked={checked} />}  onChange={handleChange} label="Enabled" />
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleAnimationClick}>
                            <ThreeSixtyIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            }
        />
        <CardMedia
            component={'img'}
            src={(item.static) ? item.static : gif}
            alt={item.artId}
        />
        <CardContent>
            <Grid container direction={'row'} spacing={2} alignItems={'center'} justifyContent="space-around">
                {item.attributes.map((trait, index) => (
                    <Grid item key={index}>
                        <Typography fontWeight={700} textAlign={'center'}>
                            {trait.trait_type}: {trait.value.replace('Formula_', '')}
                        </Typography>
                    </Grid>
                ))}
                <Grid item>
                <Typography fontWeight={700} textAlign={'center'}>
                        Art Id: {item.artId}
                </Typography>
                </Grid>
            </Grid>
            
        </CardContent>
        <CardActionArea>
            <Grid container direction={'row'} spacing={2} justifyContent={'center'} alignItems={'center'}>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Amount"
                            onChange={handlePriceChange}
                            defaultValue={getFormatedPrice(price)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xds={6}>
                    <Button variant='contained' color='info' fullWidth disabled={isDisabled} onClick={handleSavePrice}>Save</Button>
                </Grid>
            </Grid>
        </CardActionArea>
        <AnimationDialog open={openAnimation} setOpen={setOpenAnimation} artId={item.artId}/>
    </Card>

  )
}

AdminArtCard.propTypes = {
    item: PropTypes.object.isRequired
}
