import {uiTypes} from './uiTypes';

const initialState = {
    alert: {
        open: false,
        severity: 'success',
        message: '',
        title: ''
    },
    isCreateSellOrderDialogOpen: false,
    isCreateBuyOrderDialogOpen: false,
    backdrop: false,
    itemDetail: {show: false},
    marketFilter: 1,
};


export const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        
        // shows the alert message
        case uiTypes.uiOpenAlert:
            return ({
                ...state,
                alert: {open: true, severity: action.payload.severity, title: action.payload.title, message: action.payload.message}
            })

        // closes the alert message
        case uiTypes.uiCloseAlert:
            return ({
                ...state,
                alert: {...state.alert, open: false}
            })

        case uiTypes.uiOpenCreateSellOrderDialog:
            return {
                ...state,
                isCreateSellOrderDialogOpen: true
            }

        case uiTypes.uiCloseCreateSellOrderDialog:
            return {
                ...state,
                isCreateSellOrderDialogOpen: false
            }

        case uiTypes.uiOpenCreateBuyOrderDialog:
            return {
                ...state,
                isCreateBuyOrderDialogOpen: true
            }

        case uiTypes.uiCloseCreateBuyOrderDialog:
            return {
                ...state,
                isCreateBuyOrderDialogOpen: false
            }

        case uiTypes.uiOpenBackdrop:
            return {
                ...state,
                backdrop: true
            }

        case uiTypes.uiCloseBackdrop:
                return {
                    ...state,
                    backdrop: false
                }

        case uiTypes.uiShowItemDetail:
            return {
                ...state,
                itemDetail: {show: true, tokenId: action.payload}
            }

        case uiTypes.uiCloseItemDetail:
            return {
                ...state,
                itemDetail: {show: false}
            }

        case uiTypes.uiSetMarketFilter:
            return {
                ...state,
                marketFilter: action.payload
            }
        default:
            return state;
    }
}