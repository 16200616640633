export const light = {
  alternate: {
    main: '#E0E4CC',
    dark: '#edf1f7',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light',
  primary: {
    main: '#343339',
    light: '#343339',
    dark: '#343339',
    contrastText: '#fff',
  },
  secondary: {
    light: '#51FD00',
    main: '#51FD00',
    dark: '#51FD00',
    contrastText: '#343339',
  },
  info: {
    main: '#51FD00',
    light: '#343339',
    dark: '#343339',
    contrastText: '#343339',
  },
  font: {
    content: 'CabinetGrotesk-Medium',
    headline_light: 'CabinetGrotesk-Medium',
    headline_regular: 'CabinetGrotesk-Regular',
  },
  text: {
    primary: '#343339',
    secondary: '#938B8B',
    info: '#ffffff'
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#fff',
    default: '#343339',
    level2: '#f5f5f5',
    level1: '#fff',
  },
  
};

export const dark = {
  alternate: {
    main: '#1a2138',
    dark: '#151a30',
  },
  cardShadow: 'rgba(0, 0, 0, .11)',
  common: {
    black: '#000',
    white: '#fff',
  },
  mode: 'dark',
  primary: {
    main: '#343339',
    light: '#2196f3',
    dark: '#0d47a1',
    contrastText: '#fff',
  },
  secondary: {
    light: '#FFEA41',
    main: '#FFE102',
    dark: '#DBBE01',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#EEEEEF',
    secondary: '#AEB0B4',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#222B45',
    default: '#222B45',
    level2: '#333',
    level1: '#2D3748',
  },
};
