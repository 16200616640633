import { useEffect, useState } from "react";
import { auth } from "config/firebase";

export function useAuth() {
    const [currentUser, setCurrentUser] = useState(); // <-- initially undefined
  
    useEffect(() => {
      const unsub = auth.onAuthStateChanged((user) => setCurrentUser(user)); // <-- null or user object
      return unsub;
    }, []);
  
    return { currentUser };
  }