import { Card, CardContent, CircularProgress, Paper, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";
import { useSelector } from 'react-redux';
import { getFormatedPrice } from 'utils/price';

export const MoneyStats = () => {
  const {money} = useSelector(state => state.admin);
  const [data, setData] = useState([])
  const [accumulated, setAccumulated] = useState(0)
  
  const options = {
    title: "$US per day",
    legend: 'none',
  };

const generateData = () => {
  let rows = []
  let row = {}
  money.map(val => {
    const {order} = val;
    const {total, timestamp} = order;
    const date = moment.unix(timestamp).format('LL');
    const prevValue = row[date] || 0
    row[date] = prevValue + total.usd
    setAccumulated((val) => val + total.usd)
  })
  for (const [key, value] of Object.entries(row)) {
    rows.push([key, Number(getFormatedPrice(value))])
  }

  setData([
    ['day', 'total'],
    ...rows.sort(sortByDate)
  ])
}

const sortByDate = (a,b) => {
  if (moment(a[0]).valueOf() > moment(b[0]).valueOf())
    return 1
  else 
    return -1
}

useEffect(() => {
  if (money.length > 0)
    generateData()
}, [money])


  return (
    <span>
      {(data.length == 0) ? (
        <span className='screen-center'>
          <CircularProgress/>
        </span>
      ) : (
        <Card elevation={2}>
            <Chart
              chartType="LineChart"
              width={500}
              height={500}
              data={data}
              options={options}
            />
            <Typography variant='h6' textTransform={'uppercase'} textAlign={'center'} component={'span'}>
              Total: $US{Number(getFormatedPrice(accumulated))}
            </Typography>
        </Card>
      )}
    </span>
  )
}
