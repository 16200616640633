import { db } from "config/firebase";
import { discountTypes } from "./discountTypes";
import { uiShowAlert } from "reducers/uiReducer/uiActions";
import { logError } from "utils/errors";
import {
  myReadOnlyBoonjiContract,
  myReadOnlySuperBoonjiContract,
} from "utils/blockchain";
import { apiGetCurrentETHPrice } from "utils/api";
import { getDiscountedPrice, getFormatedPrice } from "utils/price";

export const getActivePhase = () => {
  return async (dispatch) => {
    try {
      const query = db.collection("phases").where("active", "==", true);
      const result = await query.get();
      if (result.empty) {
        // no phases activated, we allow referral codes
        // dispatch(setIsReferralCodeEnabled(true))
        dispatch(setActivePhase(null));
      }

      // if multiple phases are active, we get the last one
      const snapshot = result.docs.sort(sortByPhase)[0];
      const phase = snapshot.data();
      delete phase.allowList;
      dispatch(setActivePhase(phase));

      // if (phase.phase === 10){
      //     // activated phase is general, so we enable referral codes
      //     dispatch(setIsReferralCodeEnabled(true))
      // }
    } catch (error) {
      logError("getActivePhase", error, null);
    }
  };
};

const getAddresses = async (uid) => {
  const addresses = [];

  if (!uid) return addresses;

  try {
    // search the addresses of the user, registered and auth
    const snapshot = await db.collection("users").doc(uid).get();
    const data = snapshot.data();
    if (data.address) {
      const { address } = data;
      addresses.push(address);
    }
    const snapshot2 = await db
      .collection("users")
      .doc(uid)
      .collection("wallets")
      .get();
    if (!snapshot2.empty) {
      for (const doc of snapshot2.docs) {
        const wallet = doc.data();
        if (wallet.verified) addresses.push(wallet.wallet);
      }
    }
    return addresses;
  } catch (error) {
    logError("getAddresses", error, uid);
    return addresses;
  }
};

const isBoonjiCollector = async (uid) => {
  try {
    const addresses = await getAddresses(uid);
    const contract = myReadOnlyBoonjiContract();

    for (const address of addresses) {
      const response = await contract.methods.balanceOf(address).call();
      if (response !== "0") return true;
    }
    return false;
  } catch (error) {
    logError("isBoonjiCollector", error, null);
    return false;
  }
};

const isSuperBoonjiCollector = async (uid) => {
  try {
    const addresses = await getAddresses(uid);
    const contract = myReadOnlySuperBoonjiContract();

    for (const address of addresses) {
      const response = await contract.methods.balanceOf(address).call();
      if (response !== "0") return true;
    }
    return false;
  } catch (error) {
    logError("isBoonjiCollector", error, null);
    return false;
  }
};

export const getMyPhase = () => {
  return async (dispatch, getState) => {
    try {
      const { uid, email } = getState().auth;

      // TODO validate contract ownership
      const isSuperBoonji = await isSuperBoonjiCollector(uid);
      if (isSuperBoonji) {
        // we get superBoonji phase
        const snapshot = await db.collection("phases").doc("2").get();
        const phase = snapshot.data();
        delete phase.allowList;
        dispatch(setMyPhase(phase));
        // dispatch(setIsReferralCodeEnabled(false))
        dispatch(calculateMyDiscount());
        return;
      }

      const isBoonji = await isBoonjiCollector(uid);
      if (isBoonji) {
        // we get collector phase
        const snapshot = await db.collection("phases").doc("3").get();
        const phase = snapshot.data();
        delete phase.allowList;
        dispatch(setMyPhase(phase));
        // dispatch(setIsReferralCodeEnabled(false))
        dispatch(calculateMyDiscount());
        return;
      }

      // we are not in a phase, we get the general
      const query = db
        .collection("phases")
        .where("allowList", "array-contains-any", [uid, email]);
      const result = await query.get();
      if (result.empty) {
        const snapshot = await db.collection("phases").doc("10").get();
        dispatch(setMyPhase(snapshot.data()));
        // dispatch(setIsReferralCodeEnabled(true))
        dispatch(calculateMyDiscount());
        return;
      }

      // if we have multiple we get the phase with the higher discount
      const doc = result.docs.sort(sortByDiscount)[0];
      const myPhase = doc.data();
      delete myPhase.allowList;
      dispatch(setMyPhase(myPhase));
      // dispatch(setIsReferralCodeEnabled(false))
      dispatch(calculateMyDiscount());
    } catch (error) {
      logError("getMyPhase", error, null);
      dispatch(
        uiShowAlert(
          "warning",
          "Your discounts",
          "We have been unable to retrieve your discounts. Please check your connection and try again."
        )
      );
    }
  };
};

const sortByDiscount = (snapshotA, snapshotB) => {
  const a = snapshotA.data();
  const b = snapshotB.data();
  if (a.discount > b.discount) return -1;

  if (a.discount < b.discount) return 1;

  return 0;
};

const sortByPhase = (snapshotA, snapshotB) => {
  const a = snapshotA.data();
  const b = snapshotB.data();
  if (a.phase > b.phase) return -1;

  if (a.phase < b.phase) return 1;

  return 0;
};

export const calculateMyDiscount = () => {
  return async (dispatch, getState) => {
    try {
      const { activePhase, myPhase } = getState().discount;
      const { uid } = getState().auth;

      if (!uid) {
        dispatch(setDiscount({ percentage: 0, description: null, limit: 0 }));
      }

      if (myPhase?.phase <= activePhase?.phase) {
        let used = 0;
        // we search how many one of ones already got.
        const ref = await db.collection("users").doc(uid).get();

        if (ref.data().aquiredOneOfOnes) used = ref.data().aquiredOneOfOnes;
        else used = 0;

        const discount = {
          percentage: myPhase.discount,
          description: myPhase.name,
          limit: myPhase.limit,
          used,
        };
        dispatch(setDiscount(discount));
      } else {
        dispatch(setDiscount({ percentage: 0, description: null }));
      }
    } catch (error) {
      logError("calculateMyDiscount", error, null);
    }
  };
};

const setActivePhase = (phase) => ({
  type: discountTypes.setActivePhase,
  payload: phase,
});

const setMyPhase = (phase) => ({
  type: discountTypes.setMyPhase,
  payload: phase,
});

const setIsReferralCodeEnabled = (enabled) => ({
  type: discountTypes.setIsReferralCodeEnabled,
  payload: enabled,
});

const setDiscount = (discount) => ({
  type: discountTypes.setDiscount,
  payload: discount,
});

const setNoDiscountTotal = (total) => ({
  type: discountTypes.setNoDiscountTotal,
  payload: total,
});

const setDiscountTotal = (total) => ({
  type: discountTypes.setDiscountTotal,
  payload: total,
});

export const calculateTotals = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setCalculating(true));
      const { uid } = getState().auth;
      const { discount } = getState().discount;
      const { discountCode } = getState().checkout;

      if (!uid) return;

      const userSnapshot = await db.collection("users").doc(uid).get();
      const user = userSnapshot.data();

      const aquiredOneOfOnes = user.aquiredOneOfOnes || 0;

      const oneOfOnes = [];
      const wearables = [];
      const oneOfOnesSnapshot = await db
        .collection("users")
        .doc(uid)
        .collection("shoppingCart")
        .doc("current")
        .collection("oneOfOnes")
        .get();
      for (const doc of oneOfOnesSnapshot.docs) {
        oneOfOnes.push(doc.data());
      }

      const wearablesSnapshot = await db
        .collection("users")
        .doc(uid)
        .collection("shoppingCart")
        .doc("current")
        .collection("wearables")
        .get();
      for (const doc of wearablesSnapshot.docs) {
        wearables.push(doc.data());
      }

      const sumOneOfOnes = oneOfOnes.reduce(function (a, b) {
        return a + b.price;
      }, 0);
      const sumWearables = wearables.reduce(function (a, b) {
        return a + b.price;
      }, 0);
      const noDiscountTotal = sumOneOfOnes + sumWearables;
      dispatch(setNoDiscountTotal(noDiscountTotal));

      let totalWithDiscount = noDiscountTotal;
      if (discount?.percentage > 0) {
        if (aquiredOneOfOnes < discount.limit) {
          totalWithDiscount = getDiscountedPrice(
            noDiscountTotal,
            discount.percentage
          );
        }
      }

      if (discountCode) {
        totalWithDiscount = getDiscountedPrice(
          totalWithDiscount,
          discountCode.discount
        );
        // we store the used discount code. will be redeemed by crypto
        await db
          .collection("users")
          .doc(uid)
          .collection("shoppingCart")
          .doc("current")
          .set({ discountCode }, { merge: true });
      }

      const eth = await apiGetCurrentETHPrice();
      const totalEth = (getFormatedPrice(totalWithDiscount) / eth).toFixed(4);
      dispatch(setDiscountTotal({ usd: totalWithDiscount, eth: totalEth }));

      await db
        .collection("users")
        .doc(uid)
        .collection("shoppingCart")
        .doc("current")
        .set(
          { total: { usd: totalWithDiscount, eth: totalEth } },
          { merge: true }
        );
    } catch (error) {
      logError("calculateTotals", error, null);
      dispatch(
        uiShowAlert(
          "warning",
          "Total",
          "We have been unable to calculate the operation total. Please check your connection and retry."
        )
      );
    } finally {
      dispatch(setCalculating(false));
    }
  };
};

const setCalculating = (isCalculating) => ({
  type: discountTypes.setCalculating,
  payload: isCalculating,
});
