import React from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import WithLayout from "WithLayout";

// Available layouts
import { FullScreen } from "layouts/FullScreen";
import { Main as MainLayout } from "layouts";

import { Art } from "views/Art";
import { SignUp } from "views/SignUp";
import { useDispatch } from "react-redux";
import { auth } from "config/firebase";
import {
  authSetUser,
  verifyEmailAndPassword,
} from "reducers/authReducer/authActions";
import { SignIn } from "views/SignIn";
import { Marketplace } from "views/Marketplace";
import {
  getShoppingCart,
  resetShoppingCart,
} from "reducers/shoppingCartReducer/shoppingCartActions";
import { ShoppingCart } from "views/ShoppingCart";
import { Checkout } from "views/Checkout";
import { PaymentSuccessful } from "views/PaymentSuccessful";
import { Dashboard } from "views/Dashboard";
import { AuthWrapper } from "./AuthWrapper";
import { Admin } from "views/Admin";
import { EmailVerification } from "views/EmailVerification";
import { Influencers } from "views/Influencers";
import { NFT } from "views/NFT";
import { getActivePhase } from "reducers/discountReducer/discountActions";
import { OutOfBusiness } from "views/OutOfBusiness";

export const AppRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isMarketOpen =
    process.env.REACT_APP_IS_MARKET_OPEN === "true" ? true : false;

  auth.onAuthStateChanged(async (user) => {
    dispatch(resetShoppingCart());
    if (user) {
      const { uid, displayName, email, providerData } = user;
      const provider =
        providerData.length > 0 ? providerData[0].providerId : "web3";

      // if the user is email and is not verified, then we ask for verification
      if (
        providerData.length > 0 &&
        providerData[0].providerId === "password"
      ) {
        if (!user.emailVerified) {
          const success = await verifyEmailAndPassword();
          if (success) navigate("/emailVerification");
        }
      }
      dispatch(
        authSetUser(
          uid,
          displayName === null ? (email === null ? uid : email) : displayName,
          provider,
          email
        )
      );
      dispatch(getShoppingCart());
      dispatch(getActivePhase());
    }
  });

  return (
    <Routes>
      <Route
        path="/"
        element={<WithLayout component={OutOfBusiness} layout={FullScreen} />}
      />
      {/* Start Auth */}
      <Route element={<AuthWrapper />}>
        {/* {isMarketOpen && (
          <Route
            path="/shoppingCart"
            element={
                <WithLayout
                  component={ShoppingCart}
                  layout={MainLayout}
                /> 
            }
          />
        )}

        <Route
          path="/dashboard"
          element={
              <WithLayout
                component={Dashboard}
                layout={MainLayout}
              /> 
          }
        /> */}

        <Route
          path="/admin"
          element={<WithLayout component={Admin} layout={MainLayout} />}
        />

        {/* <Route
          path="/influencers"
          element={
              <WithLayout
                component={Influencers}
                layout={MainLayout}
              /> 
          }
        /> */}

        {/* {isMarketOpen && (
          <Route
            path="/checkout"
            element={
                <WithLayout
                  component={Checkout}
                  layout={MainLayout}
                /> 
            }
          />
        )} */}

        {/* {isMarketOpen && (
          <Route
            exact
            path="/paymentDone"
            element={
              <WithLayout
                component={PaymentSuccessful}
                layout={FullScreen}
              />
              
            }
          />
        )}  */}
      </Route>

      {/* <Route
        path="/"
        element={
          <WithLayout
            component={Marketplace}
            layout={MainLayout}
          />
        }
      /> */}

      <Route
        exact
        path="/signup"
        element={
          auth.currentUser ? (
            <Navigate to={"/"} replace />
          ) : (
            <WithLayout component={SignUp} layout={MainLayout} />
          )
        }
      />

      {/* <Route
        exact
        path="/nft/:tokenId"
        element={<NFT/>}
      /> */}

      <Route
        exact
        path="/signin"
        element={
          auth.currentUser ? (
            <Navigate to={"/"} replace />
          ) : (
            <WithLayout component={SignIn} layout={MainLayout} />
          )
        }
      />

      {/* <Route
        exact
        path={'/art/:artId'}
        element={<Art/>}
      />


        <Route
          exact
          path="/emailVerification"
          element={
            <WithLayout
              component={EmailVerification}
              layout={FullScreen}
            /> */}

      {/* } */}
      {/* /> */}
    </Routes>
  );
};
