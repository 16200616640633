import React, { useEffect } from 'react'
import Container from 'common/Container'
import { EmailPasswordSignIn } from './components'
import { WalletAuth } from 'views/SignUp/components/WalletAuth'
import { GoogleAuth } from 'views/SignUp/components/GoogleAuth'
import { FacebookAuth } from 'views/SignUp/components/FacebookAuth'
import { TwitterAuth } from 'views/SignUp/components/TwitterAuth'
import { Grid, Link, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import logo from 'images/logo/logo.png';

export const SignIn = () => {
    const {uid} = useSelector(state => state.auth);
    const nav = useNavigate();


    const googleEnabled = (process.env.REACT_APP_AUTH_GOOGLE_ENABLED === 'true' ? true : false); 
    const facebookEnabled = (process.env.REACT_APP_AUTH_FACEBOOK_ENABLED === 'true' ? true : false); 
    const emailEnabled = (process.env.REACT_APP_AUTH_EMAIL_ENABLED === 'true' ? true : false); 
    const twitterEnabled = (process.env.REACT_APP_AUTH_TWITTER_ENABLED === 'true' ? true : false); 
    const walletEnabled = (process.env.REACT_APP_AUTH_WALLET_ENABLED === 'true' ? true : false); 
    
    useEffect(() => {
      if (uid) {
        nav('/')
    }
    }, [uid])
    
  return (
    <Container>
            <Grid 
                container
                direction={'column'}
                justifyContent='center'
                alignItems={'center'}
                spacing={2}
            >
                <Grid item>
                    <img src={logo} width={'150px'} alt='Brendan Murphy'/>
                </Grid>
                <Grid item>
                    <Typography variant='h3' gutterBottom>
                        LOGIN
                    </Typography>
                </Grid>

                {
                    (walletEnabled) && (
                        <Grid item>
                            <WalletAuth/>
                        </Grid>
                    )
                }

                <Grid item>
                    <Typography variant='h5' gutterBottom textAlign={'center'}>
                                - OR -
                    </Typography>
                    <Grid container direction={'row'} spacing={4}>
                        {
                            (googleEnabled) && (
                                <Grid item>
                                    <GoogleAuth/>
                                </Grid>
                            )
                        }
                        {
                            (facebookEnabled) && (
                                <Grid item>
                                    <FacebookAuth/>
                                </Grid>
                            )
                        }

                        {
                            (twitterEnabled) && (
                                <Grid item>
                                    <TwitterAuth/>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
                {
                    (emailEnabled) && (
                        <Grid item>
                            <Typography variant='h5' gutterBottom textAlign={'center'}>
                                - OR -
                            </Typography>
                            <EmailPasswordSignIn/>
                        </Grid>
                    )
                }


                <Grid item>
                    <Typography variant='h6'>
                        New user? <Link href='/signup'>Sign up</Link>
                    </Typography>
                </Grid>

            </Grid>
    </Container>
  )
}
