import { Box, Card, CircularProgress, IconButton, ImageList, ImageListItem, ListSubheader } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";
import { useSelector } from 'react-redux';
import { SalesCard } from './components/SalesCard';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import InfoIcon from '@mui/icons-material/Info';
import { AnimationDialog } from 'common/AnimationDialog';
import gif from 'images/marketplace/oneOfOneLoading.gif'

export const SalesStats = () => {
  const {sales} = useSelector(state => state.admin);
  const [data, setData] = useState([])
  const [oneOfOnesList, setOneOfOnesList] = useState([])
  const [open, setOpen] = useState(false);
  const [artId, setArtId] = useState()
  
  useEffect(() => {
    if (sales.length > 0)
      generateData()
  }, [sales])

  const handleClick = (artId) => {
    setArtId(artId);
    setOpen(true);
  }
  

  const generateData = () => {
    let rows = []
    let row = {}
    sales.map(val => {
      const {order, oneOfOnes, user} = val;
      const {timestamp} = order;
      const date = moment.unix(timestamp).format('LL');
      const prevValue = row[date] || 0
      row[date] = prevValue + oneOfOnes.length
      const ones = oneOfOnes.map(one => ({...one, owner:(user.email) ? user.email : user.address }))
      for (const val of ones){
        setOneOfOnesList(oldArray => [...oldArray, val])  
      }
    })
    for (const [key, value] of Object.entries(row)) {
      rows.push([key, value])
    }
  
    setData([
      ['day', 'oneOfOnes'],
      ...rows.sort(sortByDate)
    ])
  }

  const sortByDate = (a,b) => {
    if (moment(a[0]).valueOf() > moment(b[0]).valueOf())
      return 1
    else 
      return -1
  }

  const options = {
    title: "1-of-1 Sales",
    curveType: "function",
    legend: 'none',
  };
  return (
    <div>
      {(data.length == 0) ? (
        <div className='screen-center'>
          <CircularProgress/>
        </div>
      ) : (
        <div>
        <Card elevation={2}>
          <Chart
            chartType="LineChart"
            width={'800px'}
            height={'500px'}
            data={data}
            options={options}
          />
        </Card>
          <Box
            display={'flex'}
            flexWrap={'wrap'}
            justifyContent={'space-around'}
            overflow={'hidden'}
          >
            <ImageList sx={{ width: '100%', height: '700px' }} cols={4}>
              {oneOfOnesList.map((item, index) => (
                <ImageListItem key={index}>
                  <img
                    src={(item.static) ? item.static : gif}
                    // srcSet={`${item.static}?w=248&fit=crop&auto`}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={`Art Id ${item.artId}`}
                    subtitle={`Owned by ${item.owner}`}
                    actionIcon={
                      <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`info about ${item.title}`}
                        onClick={() => handleClick(item.artId)}
                      >
                        <InfoIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
          <AnimationDialog artId={artId} open={open}  setOpen={setOpen}/>
          </div>
      )}
    </div>
  )
}
