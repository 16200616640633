import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'
import { CustomYesNoDialog } from 'common/CustomYesNoDialog'
import { useDispatch, useSelector } from 'react-redux'
import { showWalletProviderModal } from 'reducers/walletProviderReducer/walletProviderActions'
import { ethers } from 'ethers'
import { uiCloseBackdrop, uiShowAlert, uiShowBackdrop } from 'reducers/uiReducer/uiActions'
import abi from 'utils/FastSneaks.json'
import { logError } from 'utils/errors'

export const BatchMintButton = (props) => {
    const {item} = props;
    const [open, setOpen] = useState(false)
    const [yes, setYes] = useState(false)
    const {provider} = useSelector(state => state.walletProvider);
    const dispatch = useDispatch();

    const handleMintClick = () => {
      if (!provider){
        dispatch(showWalletProviderModal(false));
      } else {
        setOpen(true);
      }

    }

    const handleMint = async () => {
      try {
        dispatch(uiShowBackdrop())
        const signer = provider.getSigner();
        const fastSneaksNFT = new ethers.Contract(process.env.REACT_APP_FASTSNEAKS_ADDRESS, abi.abi, signer);
        const tx = await fastSneaksNFT.batchMint(item.selectedWallet.wallet, 1, item.oneOfOnes, item.orderId);
        await tx.wait();
        dispatch(uiShowAlert('success', 'Minting', 'Order minted. Wait a minute and refresh the screen. This order will be removed once minted.'))
      } catch (error) {
        logError('BatchMintButton', error, null)      
        dispatch(uiShowAlert('error', 'Minting', 'There was an error minting this order. Contact support.'))
      } finally {
        dispatch(uiCloseBackdrop())
      }
    }

    useEffect(() => {
      if (yes){
        handleMint()
      }
    }, [yes])
    
  return (
    <Box>
        <Button variant='contained' color='info' fullWidth onClick={handleMintClick}>
            Mint
        </Button>
        <CustomYesNoDialog open={open} setOpen={setOpen} setYes={setYes} title='Mint?' body={
            <Box>
                Are you sure you want to mint this order?<br/>
                You must be a valid operator in order to Mint this order.
            </Box>
        }/>
    </Box>
  )
}

BatchMintButton.propTypes = {
    item: PropTypes.object.isRequired
}
