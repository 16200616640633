import React from 'react'
import { CircularProgress } from '@mui/material';
import { useAuth } from 'hooks/useAuth';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const AuthWrapper = () => {
  const location = useLocation();
  const { currentUser } = useAuth();

  if (currentUser === undefined) return (
    <div className='screen-center'>
        <CircularProgress/>
    </div>
    )

  return currentUser 
    ? <Outlet />
    : <Navigate to="/signin" replace state={{ from: location }} />;
};