import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Card, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Typography } from '@mui/material';
import moment from 'moment';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import { AnimationDialog } from 'common/AnimationDialog';
import { getFormatedPrice } from 'utils/price';
import gif from 'images/marketplace/oneOfOneLoading.gif'

export const InProgressCard = (props) => {
    const {item} = props;
    const [open, setOpen] = useState(false)
    const [artId, setArtId] = useState()
    const handle3d = (artId) => {
        setArtId(artId)
        setOpen(true);
    }

  return (
        <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
                    <Grid item>
                        <Typography fontWeight={700}>
                            {(item.user.email) ? item.user.email : item.user.address}
                        </Typography>
                    </Grid>
                    <Grid item>
                        Last login {(item.user.lastLogin?.seconds) ? moment.unix(item.user.lastLogin?.seconds).fromNow() : 'unknown'}
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction={'column'} spacing={2}>
                    <Grid item>
                        <Typography fontWeight={700}>
                            U$: {getFormatedPrice((item.current) ? item.current.total.usd : item.order.total.usd)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ImageList cols={3}>
                            {item.oneOfOnes.map((image, index) => (
                                <ImageListItem key={index}>
                                    <img src={(image.static) ? image.static : gif} alt={image.artId} loading='lazy'/>
                                    <ImageListItemBar
                                        title = {`Art Id ${image.artId}`}
                                        actionIcon={
                                            <IconButton onClick={() => handle3d(image.artId)}>
                                                <ThreeSixtyIcon/>
                                            </IconButton>
                                        }
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Grid>
                </Grid>
            </AccordionDetails>
            <AnimationDialog open={open} setOpen={setOpen} artId={artId}/>
        </Accordion>
  )
}

InProgressCard.propTypes = {
    item: PropTypes.object.isRequired
}
