import React from "react";
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import { PhysicalRow } from "../PhysicalRow";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ExportToCsv } from "export-to-csv";

export const PhysicalTable = () => {
  const { sales } = useSelector((state) => state.admin);

  const handleExport = () => {
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Physicals",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);
    const data = [];

    sales.map((item) => {
      item.oneOfOnes.map((oneOfOne) => {
        const value = {
          uid: item.user.uid,
          email: item.user.email || "NA",
          orderId: item.order.id,
          artId: oneOfOne.artId,
          size: oneOfOne.size,
          address: `${item.order.selectedAddress.addressLine1} ${item.order.selectedAddress.addressLine2} ${item.order.selectedAddress.city}, ${item.order.selectedAddress.state}, ${item.order.selectedAddress.country}. ZIP ${item.order.selectedAddress.zipCode}`,
          buyer: `${item.order.selectedAddress.firstName} ${item.order.selectedAddress.lastName} ${item.order.selectedAddress.email}, phone: ${item.order.selectedAddress.telephone}`,
          comments: `${item.order.selectedAddress.comments}`,
        };
        data.push(value);
      });
    });

    csvExporter.generateCsv(data);
  };

  return (
    <Grid container direction={"column"} spacing={2} width={"80%"}>
      <Grid item>
        <Button variant="contained" color="info" onClick={handleExport}>
          Export to CSV
        </Button>
      </Grid>
      <Grid item>
        <Paper sx={{ maxWidth: "80% ", overflowX: "scroll" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">UID</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Order ID</TableCell>
                <TableCell align="center">Art ID</TableCell>
                <TableCell align="center">Size</TableCell>
                <TableCell align="center">Address</TableCell>
                <TableCell align="center">Formula</TableCell>
                <TableCell align="center">Trim</TableCell>
                <TableCell align="center">Laces</TableCell>
                <TableCell align="center">Token ID</TableCell>
                <TableCell align="center">Image</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sales.map((item, index) =>
                item.oneOfOnes.map((oneOfOne) => (
                  <PhysicalRow
                    oneOfOne={oneOfOne}
                    key={index}
                    uid={item.user.uid}
                    email={item.user.email}
                    orderId={item.order.id}
                    orderStatus={item.order.status}
                    tx={item.order.tx}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item>
        <Button variant="contained" color="info" onClick={handleExport}>
          Export to CSV
        </Button>
      </Grid>
    </Grid>
  );
};
