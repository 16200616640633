import { Grid, Typography } from '@mui/material'
import React from 'react'
import { SingleTraitFilter } from '../SingleTraitFilter'

export const TraitFilters = () => {
    const traits = [
        'skins',
        'formulas',
        'laces',
        'trims'
    ]


  return (
    <Grid
        container
        direction={'column'}
        spacing={2}
    >
        <Grid item>
            <Typography>
                Filter by traits
            </Typography>
        </Grid>

        {traits.map((val, index) => (
            <Grid item key={index}>
                <SingleTraitFilter trait_type={val} />
            </Grid>
        ))}

    </Grid>
  )
}
