import React from 'react';
import Grid from '@mui/material/Grid';
import { Button, Link, Typography } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Container from 'common/Container';

const Footer = () => {
  return (
    <Container>
      <Grid 
        container 
        direction='row'
        justifyContent="space-evenly"
        alignItems="flex-start"
        spacing={2}
      >
          <Grid item xs={12} md={8}>
            <Typography variant='h6' textAlign={{xs:'center', md:'left'}}>
                ©2023 FAST SNEAKS. / BRENDAN MURPHY. - ALL RIGHTS RESERVED
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container direction={'row'} justifyContent={'center'} alignItems={'center'}>
              <Grid item>
                <SupportAgentIcon/>
              </Grid>
              <Grid item>
                <Link href='mailto:support@fastsneaksproject.com'>support@fastsneaksproject.com</Link>
              </Grid>
            </Grid>
          </Grid>

      </Grid>
    </Container>
  )
}




export default Footer;
