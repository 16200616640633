import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedFormula, addSelectedLace, addSelectedSkin, addSelectedTrim, removeSelectedFormula, removeSelectedLace, removeSelectedSkin, removeSelectedTrim } from 'reducers/traitsReducer/traitsActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';

export const SingleTraitFilter = (props) => {
    const {trait_type} = props;
    const traits = useSelector(state => state.traits);
    const trait = traits[trait_type] || []
    const selectedTraits = traits[`selected${capitalizeFirstLetter(trait_type)}`] || []
    const dispatch = useDispatch();

    function capitalizeFirstLetter(string) {
        return string[0].toUpperCase() + string.slice(1);
    }

    const handleChange = (e) => {
        const val = e.target.value;
        if (e.target.checked) {
            addSelectedTrait(val)
        } else {
            removeSelectedTrait(val)
        }
    }

    const addSelectedTrait = (value) => {
        switch (trait_type){
            case 'skins':
                dispatch(addSelectedSkin(value));
                break;
            case 'formulas':
                dispatch(addSelectedFormula(value));
                break;
            case 'laces':
                dispatch(addSelectedLace(value));
                break;
            case 'trims':
                dispatch(addSelectedTrim(value));
                break;
        }
    }
    const removeSelectedTrait = (value) => {
        switch (trait_type){
            case 'skins':
                dispatch(removeSelectedSkin(value));
                break;
            case 'formulas':
                dispatch(removeSelectedFormula(value));
                break;
            case 'laces':
                dispatch(removeSelectedLace(value));
                break;
            case 'trims':
                dispatch(removeSelectedTrim(value));
                break;
        }
    }

    const handleOnClick = (e) => {
        if (selectedTraits > 3)
            return;
    }

  return (
    <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        >
        <Typography>{capitalizeFirstLetter(trait_type)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container direction={'column'}>
            <FormGroup>
                {trait.map((val) => (
                    <Grid item>
                        <FormControlLabel onChange={handleChange} control={<Checkbox onClick={handleOnClick} value={val.trait_type} />} label={`${val.trait_type.replace('Formula_', '')} used in ${val.usedIn}`} />
                    </Grid>
                ))}
            </FormGroup>
        </Grid>
        </AccordionDetails>
    </Accordion>
  )
}

SingleTraitFilter.propTypes = {
    trait_type: PropTypes.string.isRequired
}
