import React from "react";

import { Grid, Typography } from "@mui/material";
import img from "./Black Flat.PNG";

export const OutOfBusiness = () => {
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh" }}
      padding={{ xs: 2, md: 10 }}
    >
      <Grid item xs={12}>
        <Typography variant={"h5"}>
          Introduced in 2023 alongside the Boonji Project by artist Brendan
          Murphy, the FAST Sneaks project is now being expanded into its own
          brand. <br />
          <br />
          This website stands as the original domain where users came to claim
          their pairs.
          <br />
          <br />
          Follow @fastsneaksproject for further updates.
        </Typography>
      </Grid>
      <Grid item marginTop={3} xs={12}>
        <img src={img} width={"300px"} alt="FAST Sneaks project" />
      </Grid>
    </Grid>
  );
};
