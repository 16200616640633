import moment from 'moment';
import {utils} from 'ethers';
import jwt_decode from "jwt-decode";

import {auth, db, googleAuthProvider, facebookAuthProvider, twitterAuthProvider, firebase} from 'config/firebase';
import {requestAuthToken, requestAuthMessage} from 'utils/api';
import {showWalletProviderModal} from 'reducers/walletProviderReducer/walletProviderActions';
import {authTypes} from './authTypes';
import {localStorageAdd, localStorageGet} from 'utils/localStorage';
import { logError } from 'utils/errors';
import { uiShowAlert } from 'reducers/uiReducer/uiActions';
import { getAddress } from 'ethers/lib/utils';

export const authSignInTwitter = (result) => {
    return async (dispatch) => {
        try{
            const {user} = result;
            const {displayName, email, uid, phoneNumber, photoURL, refreshToken} = user;
            
            dispatch(authSetUser(uid, displayName, 'twitter.com', email));
    
            // we store user data
            const data = {
                auth: 'twitter',
                displayName, 
                email, 
                uid,
                phoneNumber,
                photoURL, 
                refreshToken
            }
            await saveUserData(uid, data)
            // we store in firestore the last login time
            await saveLastLogin(uid);
        } catch ( error ) {
            logError('authSignInTwitter', error, null);

            if (error.message.includes('An account already exists with the same email address')){
                dispatch(uiShowAlert('warning', 'Twitter', 'We are sorry but Looks like your Twitter`s email is already registered.'));
            }
        }
    }
}

export const authStartSignUpUserMedia = (mediaProvider, popUp = true) => {
    return async (dispatch, getState) => {
        try {
            let provider;
            let providerId;
            switch(mediaProvider){
                case 'google':
                    provider = new googleAuthProvider();
                    providerId = 'google.com'
                    break;
                case 'facebook':
                    provider = new facebookAuthProvider();
                    provider.addScope('email');
                    provider.addScope('public_profile');
                    providerId= 'facebook.com'
                    break;
                case 'twitter':
                    provider = new twitterAuthProvider();
                    providerId = 'twitter.com'
                    break;
                default:
                    provider = new googleAuthProvider();
                    providerId = 'google.com'
                    break;
            }

            let result;
            
            try {
                if (popUp)
                    result = await auth.signInWithPopup(provider);
                else{
                    await auth.signInWithRedirect(provider);
                    result = await auth.getRedirectResult();
                }
            } catch ( error ) {
                logError('authStartSignUpUserMedia', error, null)
            }

            const {user} = result;
            const {displayName, email, uid, phoneNumber, photoURL, refreshToken} = user;
            
            dispatch(authSetUser(uid, displayName, providerId, email));

            // we store user data
            const data = {
                auth: mediaProvider,
                displayName, 
                email, 
                uid,
                phoneNumber,
                photoURL, 
                refreshToken
            }
            await saveUserData(uid, data)
            // we store in firestore the last login time
            await saveLastLogin(uid);
        } catch ( error ) {
            const {message} = error
            
            if (message.includes('popup has been closed')){
                return;
            }
            
            // if we don't have the user logged, then we show an error
            const {uid} = getState().auth;
            if (!uid){
                dispatch(uiShowAlert('error','Login error', 'We have an error trying to log you in. We are sorry for the inconvenience. Please refresh and try again.'));
            }

            logError(`Signing ${mediaProvider} user`, error)
        }
    }

}

export const authStartSignUpEmailPassword = (userEmail, password) => {
    return async (dispatch, getState) => {
        try {
            const result = await auth.createUserWithEmailAndPassword(userEmail, password);
            const {user} = result;  
            const {displayName, email, uid, phoneNumber, photoURL, refreshToken} = user;
            const data = {
                auth: 'email',
                displayName, 
                email, 
                uid,
                phoneNumber,
                photoURL, 
                refreshToken
            }
            
            await saveUserData(uid, data)
            // we store in firestore the last login time
            await saveLastLogin(uid);
            dispatch(authSetUser(uid, email, 'email', email));
        } catch ( error ) {
            logError('authStartSignUpEmailPassword', error, null)

            const {uid} = getState().auth;
            if (!uid){
                dispatch(uiShowAlert('error','Login error', 'We have an error trying to log you in. We are sorry for the inconvenience. Please refresh and try again.'));
            }

            logError(`Signing email user`, error)
        }

    }
}

export const signInWithEmailAndPassword = (email, password) => {
    return async (dispatch, getState) => {
        try {
            await auth.signInWithEmailAndPassword(email, password)
        } catch ( error ) { 
            if (error.message.includes('The password is invalid or the user does not have a password')){
                dispatch(uiShowAlert('warning', 'User Login' , 'Provided credentials are not ok.'))    
                return;
            }
            if (error.message.includes('There is no user record corresponding to this identifier')){
                dispatch(uiShowAlert('warning', 'User Login' , 'Provided credentials are not ok.'))    
                return;
            }
            logError('signInWithEmailAndPassword', error, null)
            dispatch(uiShowAlert('warning', 'User Login' , 'There was an error trying to log you in. Please refresh and retry.'))
        }
    }
}

export const authStartSignUpUserAddress = (add) => {
    return async (dispatch, getState) => {
        
        if (!add)
            return;

        try {
            const address = utils.getAddress(add);
            // we search first for token stored on storage, because this might be an existing user
            const storage = localStorageGet(address);
            if (storage?.token) {
                // we need to verify if the token is still valid, because it might have expired.
                // if it is expired, we request a new one by providing signature stored on db that only we can read.
                let token = storage.token;
                if (isTokenExpired(token)){
                    const signature = await getStoredSignature(address);
                    const response = await requestAuthToken(address, signature);
                    token = response.message;
                }
                
                await auth.signInWithCustomToken(token);
                dispatch(authSetUser(address, address, 'web3', null));
            } else {
                // this is a new user, so we are registering it by getting a message to sign
                const response = await requestAuthMessage(address);
                
                const {provider} = getState().walletProvider;
    
                // we don't have a provider? this is weird, we will launch the connect provider modal
                if (!provider){
                    await dispatch(showWalletProviderModal())
                }
    
                // if we don't have a provider, then we will fail
                const signer = await provider.getSigner();
                const signature = await signer.signMessage(response.message);
                const signatureResponse = await requestAuthToken(address, signature);
                const token = signatureResponse.message;
                await auth.signInWithCustomToken(token);
                await dispatch(authSetUser(address, address, 'web3', null));            
    
                // we store the token on local Storage to support user switching
                localStorageAdd(address, {token});
            }

            // we store the type used to authenticate the user
            await saveUserData(address, {auth: 'web3', address, uid: address})
    
            // we store in firestore the last login time
            await saveLastLogin(address);
        } catch ( error ) {
            logError('Signing web3 user ', error, add)
            const {message} = error;
            
            if (message.includes('user rejected signing.')){
                dispatch(uiShowAlert('warning', 'Wallet Connector', `You denied message signature. We can't let you in.`));
                return;
            }

            dispatch(uiShowAlert('error','Login error', 'We have an error trying to log you in. We are sorry for the inconvenience. Please refresh and try again.'));
        }
    }
}

export const validateSignature = async (provider, add) => {
    try {
        const address = getAddress(add);
        const signer = await provider.getSigner();
        const response = await requestAuthMessage(address);
        const signature = await signer.signMessage(response.message);
        const result = await requestAuthToken(address, signature);
        return result.ok;
    } catch (error) {
        logError('validateSignature', error, null)
        return false;
    }
}

/**
 * decodes and verifies if the token is expired or not
 * @param {*} token 
 * @returns 
 */
const isTokenExpired = (token) => {
    const decoded = jwt_decode(token);
    const now = moment().unix();
    const {exp} = decoded;
    return (now > exp);
}

const saveLastLogin = async (uid) => {  
    try {
        await db.collection('users').doc(uid).set({lastLogin: moment().toDate()}, {merge:true})
    } catch ( error ) {
        await logError('Saving last login', error, uid);
    }
}

const saveUserData = async (uid, data) => {  
    try {
        const snapshot = await db.collection('users').doc(uid).get();
        if (!snapshot.exists)
            await db.collection('users').doc(uid).set(data, {merge:true})
    } catch ( error ) {
        await logError('Saving auth data', error, uid);
    }
}

// we get the last signed signature for this address in order to request a token refresh.
// This way we avoid asking the user to sign again
const getStoredSignature = async (address) => {
    try {
        const snapshot = await db.collection('auth').doc(address).get();
        
        if (!snapshot.exists)
            return null;

        const {signature} = snapshot.data();
        return signature;
    } catch ( error ) {
        await logError('Getting stored signature', error, address);
        throw new Error ('There was a problem accessing the database. Please try again later.')
    }
}

export const authSetUser = (uid, displayName, provider, email) => ({
    type: authTypes.setUser,
    payload: {uid, displayName, provider, email}
})

export const authStartLogOut = () => {
    return async (dispatch) => {
        await auth.signOut();
        dispatch(logOut());
    }
}

export const logOut = () => ({
    type: authTypes.logOutUser
})

export const verifyEmailAndPassword = async () => {
    try {
        const {uid} = firebase.auth().currentUser;
        const ref = db.collection('users').doc(uid);
        const snapshot = await ref.get();
        if (snapshot.exists){
            const data = snapshot.data();
            if (!data.emailVerificationSent){
                await firebase.auth().currentUser.sendEmailVerification({url: process.env.REACT_APP_CONTINUE_URL_EMAIL_VERFICATION});
                await ref.set({emailVerificationSent: true}, {merge:true});
            }
            return true;
        }
        return false;
    } catch ( error ) {
        logError('verifyEmailAndPassword', error, null)
        return false;
    }
}