import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { MoneyStats } from './components/MoneyStats'
import { SalesStats } from './components/SalesStats'
import { UserStats } from './components/UserStats'

export const AdminStats = () => {
  return (
    <Grid container direction={'column'} spacing={2}>
        <Grid item xs={12}>
            <Grid container direction={'row'} spacing={2} justifyContent={'center'} alignItems={'center'} >
                <Grid item xs={12} md={6}>
                    <MoneyStats/>    
                </Grid>
                <Grid item xs={12} md={6}>
                    <UserStats/>
                </Grid>
            </Grid>
        </Grid>

        <Grid item xs={12}>
            <SalesStats/>
        </Grid>
    </Grid>
  )
}
