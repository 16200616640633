import React, {useEffect, useRef, useState} from 'react'

import PropTypes from 'prop-types'
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import useWindowDimensions from 'hooks/useWindowDimensions';
import ReactPlayer from 'react-player'

import {OrbitControls} from './OrbitControls';
import shoeObject from './fastShoesModel.glb';
import {getBase64FromUrl} from 'utils/base64';
import { storage } from 'config/firebase';

import './FastShoesAnimation.css'
import { logError } from 'utils/errors';

export const FastShoesAnimation = props => {
  const {background, skin, trim, laces, formula, generateGif = false, artId} = props;
  const canvasRef = useRef();
  const canvas = canvasRef.current;
  const frame = useRef(0);
  const maxFrames = 32;
  const scene = new THREE.Scene();
  const pivot = new THREE.Group();

  let shoe;
  const loadShoe = async () => {
    try {
      const skinImage = await getBase64FromUrl(skin)
      const formulaImage = await getBase64FromUrl(formula)
      const trimImage = await getBase64FromUrl(trim)
      const textureLoader = new THREE.TextureLoader();
      const skinTexture =  await textureLoader.loadAsync(skinImage)
      skinTexture.flipY = false;
      skinTexture.encoding = THREE.sRGBEncoding;

      const formulaTexture =  await textureLoader.loadAsync(formulaImage)
      formulaTexture.flipY = false;
      formulaTexture.encoding = THREE.sRGBEncoding;

      const trimTexture =  await textureLoader.loadAsync(trimImage)
      trimTexture.flipY = false;
      trimTexture.encoding = THREE.sRGBEncoding;
      
      const shoeLoader = new GLTFLoader();
      const model = await shoeLoader.loadAsync(shoeObject);
      shoe = model.scene;
  
      shoe.children.map(async (mesh) => {
        if (mesh.isMesh){
          mesh.material.color.setHex(0xFFFFFF)

          // // skin mesh
          if (mesh.name === 'PM3D_shoes_7ml_1'){
              const skinMaterial = new THREE.MeshPhongMaterial(
                {
                  map: skinTexture}
              );
              mesh.material = skinMaterial;
              
            
          }
  
          // Back trim and front
          if (mesh.name === 'PM3D_shoes_7ml_3_1'){ // || mesh.name === 'PM3D_shoes_7ml_4_primitive7'){
            mesh.material = mesh.material.clone();
            mesh.material.color.set(trim);
            const trimMaterial = new THREE.MeshLambertMaterial(
              {
                map: trimTexture}
            );
            mesh.material = trimMaterial;
          }

          if (mesh.name === 'PM3D_shoes_7ml_4_primitive0'){
            mesh.material = mesh.material.clone();
            mesh.material.metalness = 1
            mesh.material.color.set('silver');
          }

          if (mesh.name === 'PM3D_shoes_7ml_4_primitive1' && trim.search('black') > 0){
            mesh.material = mesh.material.clone();
            // mesh.material.metalness = 1
            mesh.material.color.set('black');
          }

          if (mesh.name === 'PM3D_shoes_7ml_4_primitive16' && trim.search('black') > 0){
            mesh.material = mesh.material.clone();
            // mesh.material.metalness = 1
            mesh.material.color.set('black');
          }

          if (mesh.name === 'PM3D_shoes_7ml_4_primitive4' && trim.search('black') > 0){
            mesh.material = mesh.material.clone();
            // mesh.material.metalness = 1
            mesh.material.color.set('black');
          }
          if (mesh.name === 'PM3D_shoes_7ml_4_primitive7' && trim.search('black') > 0){
            mesh.material = mesh.material.clone();
            // mesh.material.metalness = 1
            mesh.material.color.set('black');
          }

          if (mesh.name === 'PM3D_shoes_7ml_4_primitive13' && trim.search('black') > 0){
            mesh.material = mesh.material.clone();
            // mesh.material.metalness = 1
            mesh.material.color.set('black');
          }

          if (mesh.name === 'PM3D_shoes_7ml_4_primitive5' && trim.search('black') > 0){
            mesh.material = mesh.material.clone();
            // mesh.material.metalness = 1
            mesh.material.color.set('black');
          }
  
          if (mesh.name === 'PM3D_shoes_7ml_4_primitive15' && trim.search('black') > 0){
            mesh.material = mesh.material.clone();
            // mesh.material.metalness = 1
            mesh.material.color.set('black');
          }
          // laces
          if (
              mesh.name === 'PM3D_shoes_7ml_4_primitive3' || 
              mesh.name === 'PM3D_shoes_7ml_4_primitive2' || 
              // mesh.name === 'PM3D_shoes_7ml_4_primitive5' || 
              mesh.name === 'PM3D_shoes_7ml_4_primitive8' || 
              mesh.name === 'PM3D_shoes_7ml_4_primitive9' || 
              mesh.name === 'PM3D_shoes_7ml_4_primitive10' || 
              mesh.name === 'PM3D_shoes_7ml_4_primitive12' || 
              mesh.name === 'PM3D_shoes_7ml_4_primitive14' || 
              mesh.name === 'PM3D_shoes_7ml_4_2' || 
              mesh.name === 'PM3D_shoes_7ml_4_3'
            ){
            mesh.material = mesh.material.clone();
            mesh.material.color.set(laces);
            mesh.material.needsUpdate = true;
          }
  
          // formula
          if (mesh.name === 'PM3D_shoes_7ml_2_1'){
            const formulaMaterial = new THREE.MeshLambertMaterial(
              {
                map: formulaTexture}
            );
            mesh.material = formulaMaterial;
          }
        }
        
      })
      shoe.rotateY(Math.PI /2)
      shoe.position.set(-1,-0.1,0)
      controls.target.set(0,0,-0.5)
      
      // if (generateGif)
      //   camera.position.set(-1.3,0.3,2);
        
      // scene.add(shoe);  
      pivot.add(shoe);
      scene.add(pivot);
      setStartAnimation(true)
    } catch ( error ) {
      logError('FastShoesAnimation', error, null)      
    }
  }

  loadShoe();
  
  const { height, width } = useWindowDimensions();
  const sizes = {height, width};


  // lights
  // White directional light at half intensity shining from the top.
  const directionalLight1 = new THREE.DirectionalLight( 0xf2f2f2, 0.9 );
  directionalLight1.position.set(3,0,0);
  scene.add( directionalLight1 );

  
  const directionalLight2 = new THREE.DirectionalLight( 0xf2f2f2, 0.7 );
  directionalLight2.position.set(0,1,3);
  scene.add( directionalLight2 );
  
  const directionalLight3 = new THREE.DirectionalLight( 0xf2f2f2, 0.7 );
  directionalLight3.position.set(0,1,-3);;
  scene.add( directionalLight3)
  
  const directionalLight4 = new THREE.DirectionalLight( 0xf2f2f2, 0.7 );
  directionalLight4.position.set(-3,0,0);;
  scene.add( directionalLight4)

  
  
  const camera = new THREE.PerspectiveCamera(
    45,
    sizes.width / sizes.height,
    0.1,
    1000
  );
  
  camera.position.set(0,0.4,2);
  scene.add(camera);
  
  

  const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true,
    preserveDrawingBuffer: (generateGif) ? true : false
  });

  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.setClearColor( 0x000000, 0 ); // the default
  
  // CONTROLS
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableZoom = true;
  controls.enablePan = false; 
  controls.enableDamping = true;
  controls.dampingFactor = 0.03;
  controls.minDistance = 1.3;
  controls.maxDistance = 10;
  controls.zoomSpeed = 0.3;
  controls.keys = {
    LEFT: "ArrowLeft",
    UP: "ArrowUp", 
    RIGHT: "ArrowRight", 
    BOTTOM: "ArrowDown", 
  };
  const requestRef = React.useRef()

  const rotation = (Math.PI * 2) / 30

  const tick = async () => {
    controls.update();
    
    
    if (shoe && generateGif){
      
      pivot.rotation.y += rotation;
      // we are generating the gif. We will store all files in storage and then close it
      if (frame.current < maxFrames){
        const strMime = 'image/png';
        const imgData = renderer.domElement.toDataURL(strMime);
        const storageRef = storage.ref();
        const imageRef = storageRef.child(`gif/${artId}/frame${frame.current}.png`)
        try {
          await imageRef.putString(imgData, 'data_url')
        } catch ( error ){
          logError('FastShoesAnimation', error, null)      
        }
        frame.current = frame.current + 1;
      } else {
        window.opener = null;
        window.open("about:blank", "_self");
        window.close();
      }
    }
    
    renderer.render(scene, camera)
    requestRef.current = window.requestAnimationFrame(tick);
  };
  
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect( () => {
    if (startAnimation){
      requestRef.current =  window.requestAnimationFrame(tick);
      return () => window.cancelAnimationFrame(requestRef.current);
    }
  }, [startAnimation])

  useEffect(() => {
    window.addEventListener("resize", () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;
    
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();
    
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    }); 
  }, []); // Make sure the effect runs only once
  
  
  return (
    <div>
        <div style={{position: 'relative', width:sizes.width, height:sizes.height, textAlign: 'center'}}>
          {(background.type === 'video') ? (
              <ReactPlayer playing={true} url={background.url} loop controls={false} muted width={'100%'} height={'100%'} style={{zIndex: -1}}/>
          ) : (
            <img src={background.url} width={sizes.width} height={sizes.height} alt='Fast Sneaks Project'/>
          )}
          <canvas ref={canvasRef} style={{position:'absolute', top:'50%', left:'50%', transform: 'translate(-50%, -50%)'}}/>
        </div>
    </div>
  )
}

FastShoesAnimation.propTypes = {
  skin: PropTypes.string.isRequired,
  laces: PropTypes.string.isRequired,
  background: PropTypes.object.isRequired,
  formula: PropTypes.string.isRequired,
  trim: PropTypes.string.isRequired,
  generateGif: PropTypes.bool,
  artId: PropTypes.number.isRequired,
}