import { Link, Typography } from '@mui/material'
import React from 'react'

export const SignInMenu = () => {
  return (
    <Link href={'/signin'} underline='none'>
        <Typography variant='h6' color='textPrimary'>Log in</Typography>
    </Link>
  )
}
