import React from 'react'
import {Provider} from 'react-redux'
import { BrowserRouter } from 'react-router-dom';

import { createBrowserHistory } from 'history';

import {store} from './store'
import { AppRoutes } from 'router/AppRoutes';
import { CustomAlert } from 'common/CustomAlert';
import { CustomBackDrop } from 'common/CustomBackDrop';

import './FastShoes.css';

const browserHistory = createBrowserHistory();
export const FastShoes = () => {
  return (
    <Provider store={store}>
        <BrowserRouter>
          <AppRoutes/>
          <CustomAlert/>
          <CustomBackDrop/>
        </BrowserRouter>
    </Provider>
  )
}
