import { Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AutorenewIcon from '@mui/icons-material/Autorenew';
import randomstring from 'randomstring';
import { db } from 'config/firebase';
import { useDispatch } from 'react-redux';
import { uiShowAlert } from 'reducers/uiReducer/uiActions';

export const GenerateAdminCodes = () => {
    const [code, setCode] = useState('')
    const [discount, setDiscount] = useState(50);
    const [limit, setLimit] = useState(5);
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(true);

    const handleAutoGenerate = () => {
        setCode(randomstring.generate({length: 12, charset: 'alphanumeric'}));
    }

    useEffect(() => {
      if (code === ''){
        setIsDisabled(true);
        return
      }

      if (!code) {
        setIsDisabled(true)
        return;
      }

      if (isNaN(discount)){
        setIsDisabled(true)
        return
      }

      if (discount < 1){
        setIsDisabled(true)
        return
      }

      if (limit < 1){
        setIsDisabled(true)
        return
      }

      if (isNaN(limit)){
        setIsDisabled(true)
        return
      }

      setIsDisabled(false)
        
    }, [code, discount, limit])
    

    const handleCodeChange = (e) => {
        const value = e.target.value;
        setCode(value)
    }

    const handleDiscountChange = (e) => {
        const value = e.target.value;
        setDiscount(value)
    }

    const handleLimitChange = (e) => {
        const value = e.target.value;
        setLimit(value)
    }

    const handleGenerate = async () => {
        // we make sure it doesn't exists
        const query = db.collection('adminCodes').where('code', '==', code);
        const result = await query.get();
        if (result.empty){
            await db.collection('adminCodes').doc(code.toString()).set({
                code, limit: Number(limit), discount: Number(discount), redeemed: false, type: 'admin'
            })
            dispatch(uiShowAlert('success', 'Admin code', 'Code generated successfully.'))
            setCode('')
        } else {
            dispatch(uiShowAlert('warning', 'Admin code', 'Provided admin code already exists.'));
        }
    }
  return (
    <Grid container direction={'row'}>
        <Grid item>
            <Grid container direction={'row'}>
                <Grid item>
                    <TextField  label="code" variant="standard" value={code} onChange={handleCodeChange}/>
                </Grid>
                <Grid item>
                    <IconButton onClick={handleAutoGenerate}>
                        <AutorenewIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>

        <Grid item>
            
            <TextField  
            label="Discount" 
            variant="standard" 
            value={discount} 
            onChange={handleDiscountChange}
            InputProps={{
                startAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
            />
        </Grid>

        <Grid item>
            <TextField  label="Limit" variant="standard"  value={limit} onChange={handleLimitChange}/>
        </Grid>

        <Grid item>
            <Button variant='contained' color='info' size='large' onClick={handleGenerate} disabled={isDisabled}>
                Generate Admin Code
            </Button>
        </Grid>
    </Grid>
  )
}
