import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export const CustomYesNoDialog = (props) => {
    const  {open, setOpen, title, body, setYes} = props;

    const handleClose = () => {
        setOpen(false);
    }

    const handleYes = () => {
        setYes(true);
        handleClose();
    }

    const handleNo = () => {
        setYes(false);
        handleClose()
    }
  return (
    <Dialog
        open={open}
        onClose={handleClose}
    >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            {body}
        </DialogContent>

        <DialogActions>
            <Button size='large' variant='outlined' onClick={handleNo} color='primary'>No</Button>
            <Button size='large' variant='contained' onClick={handleYes} color='info'>Yes</Button>
        </DialogActions>
    </Dialog>
  )
}

CustomYesNoDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    title: PropTypes.string,
    body: PropTypes.object.isRequired,
    setYes: PropTypes.func.isRequired
}
