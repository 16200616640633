import React, {useEffect, useState} from 'react'
import { EmailOutlined, Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import PasswordStrengthBar from 'react-password-strength-bar'
import { useDispatch } from 'react-redux'
import { authStartSignUpEmailPassword, signInWithEmailAndPassword } from 'reducers/authReducer/authActions'
import { validateEmail } from 'utils/email'

export const EmailPasswordSignIn = () => {
  const [formData, setFormData] = useState({email: '', password: '', passwordValidation: ''})
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [helperTexts, setHelperTexts] = useState({email: '', password: '', passwordValidation: ''})
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  
  const dispatch = useDispatch();

  const handleSignIn = () => {
    dispatch(signInWithEmailAndPassword(formData.email, formData.password));
  }
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (e) => {e.preventDefault();};

  const handleEmailChange = (e) => {
    e.preventDefault();
    setFormData({...formData, email: e.target.value})
  }
  const handlePasswordChange = (e) => {
    e.preventDefault();
    setFormData({...formData, password: e.target.value})
  }

  const validateForm = () => {
    // we validate the email
    if (validateEmail(formData.email))
      setIsEmailValid(true)
    else
      setIsEmailValid(false)

    if (isEmailValid && formData.password)
      setIsSubmitDisabled(false)
    else
      setIsSubmitDisabled(true)
  }
    
  useEffect(() => {
    validateForm()    
  }, [formData])

  useEffect(() => {
    validateForm()    
  }, [])

  const onFocus = (event) => {
    if(event.target.autocomplete)
    {
      event.target.autocomplete = "new-password";
    }
  }
  return (
    <Grid
      container
      direction={'column'}
      spacing={2}
    >
        <Grid item>
          <FormControl variant="outlined" fullWidth>
            <TextField
            inputProps={{
              autoComplete: 'new-password'
            }}
              onFocusCapture={onFocus}
              onFocus={onFocus}
              type='text'
              required
              autoComplete={'new-password'}
              value={formData.email}
              onInput={handleEmailChange}
              error={(isEmailValid) ? false : true}
              label="Email"
              id="outlined-start-adornment"
              sx={{ m: 1, width: '25ch' }}
              InputProps={{
                startAdornment: <InputAdornment position="start"><EmailOutlined/></InputAdornment>,
              }}
            />
            <FormHelperText error>
              {helperTexts.email}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl fullWidth variant="outlined">
            <InputLabel  htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              autoComplete={'new-password'}
              required
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onFocus={onFocus}
              onInput={handlePasswordChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            <FormHelperText error>
              {helperTexts.password}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item>
          <Button fullWidth variant={'contained'} color={'info'} onClick={handleSignIn} disabled={isSubmitDisabled}>Sign in</Button>
        </Grid>
        </Grid>
  )
}
