import {marketplaceTypes} from './marketplaceTypes';

const initialState = {
    oneOfOnesList: [],
    oneOfOneError: false,
    wearables: [],
    phases: [],
    activePhase: null,
};


export const marketplaceReducer = (state = initialState, action) => {
    switch (action.type) {
        case marketplaceTypes.addOneOfOne:
            return {
                ...state,
                oneOfOnesList: [...state.oneOfOnesList, action.payload]
            }

        case marketplaceTypes.updateOneOfOne:
            return {
                ...state,
                oneOfOnesList: [...state.oneOfOnesList.map(val => (val.artId === action.payload.artId) ? action.payload : val)]
            }

        case marketplaceTypes.removeOneOfOne:
            return {
                ...state,
                oneOfOnesList: [...state.oneOfOnesList.filter(val => val.artId !== action.payload.artId)]
            }
        case marketplaceTypes.setErrorOnOneOfOnes:
            return {
                ...state,
                oneOfOneError: true
            }

        case marketplaceTypes.addWearable:
            return {
                ...state,
                wearables: action.payload
            }

        case marketplaceTypes.resetMarketplace:
            return {
                ...state,
                oneOfOnesList: []
            }

        case marketplaceTypes.setActivePhase:
            return {
                ...state,
                activePhase: action.payload
            }

        case marketplaceTypes.getPhases:
            return {
                ...state,
                phases: action.payload
            }

        default:
            return state;
    }
}