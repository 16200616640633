import { Google } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { authStartSignUpUserMedia } from 'reducers/authReducer/authActions'

export const GoogleAuth = () => {
  const dispatch = useDispatch()

  const handleClick = () => {
    if (isMobile)
      dispatch( authStartSignUpUserMedia('google', false));
    else
      dispatch( authStartSignUpUserMedia('google', true));
  }

  return (
    <IconButton onClick={handleClick} size='large' >
        <Google sx={{ fontSize: 40 }}/>
    </IconButton>
  )
}
