import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, Dialog, IconButton, Toolbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { Art } from 'views/Art';

export const AnimationDialog = (props) => {
    const {open, artId, setOpen} = props;

    const handleClose = () => {
        setOpen(false);
    }

  return (
    <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
    >
        <AppBar sx={{ position: 'relative', backgroundColor:'black' }} elevation={0} >
            <Toolbar>
                <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Close
                </Typography>
            </Toolbar>
        </AppBar>

        <Art manualArtId={artId}/>
    </Dialog>
  )
}

AnimationDialog.propTypes = {
    artId: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
}
