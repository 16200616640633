import { traitsTypes } from "./traitsTypes";

const initialState = {
    formulas: [],
    skins: [],
    laces: [],
    trims: [],
    selectedFormulas: [],
    selectedSkins: [],
    selectedLaces: [],
    selectedTrims: [],
    artList: [],
    firstDoc: null,
    lastDoc: null,
    limit: 10
};


export const traitsReducer = (state = initialState, action) => {
    switch (action.type) {
        case traitsTypes.setFormulas:
            return {
                ...state,
                formulas: action.payload
            }
        case traitsTypes.setSkins:
            return {
                ...state,
                skins: action.payload
            }
        case traitsTypes.setLaces:
            return {
                ...state,
                laces: action.payload
            }
        case traitsTypes.setTrims:
            return {
                ...state,
                trims: action.payload
            }

        case traitsTypes.addSelectedFormula:
            return {
                ...state,
                selectedFormulas: [...state.selectedFormulas, action.payload]
            }
        case traitsTypes.removeSelectedFormula:
            return {
                ...state,
                selectedFormulas: [...state.selectedFormulas.filter(val => val !== action.payload)]
            }

        case traitsTypes.addSelectedSkin:
            return {
                ...state,
                selectedSkins: [...state.selectedSkins, action.payload]
            }
        case traitsTypes.removeSelectedSkin:
            return {
                ...state,
                selectedSkins: [...state.selectedSkins.filter(val => val !== action.payload)]
            }
        case traitsTypes.addSelectedLace:
            return {
                ...state,
                selectedLaces: [...state.selectedLaces, action.payload]
            }
        case traitsTypes.removeSelectedLace:
            return {
                ...state,
                selectedLaces: [...state.selectedLaces.filter(val => val !== action.payload)]
            }
        case traitsTypes.addSelectedTrim:
            return {
                ...state,
                selectedTrims: [...state.selectedTrims, action.payload]
            }
        case traitsTypes.removeSelectedTrim:
            return {
                ...state,
                selectedTrims: [...state.selectedTrims.filter(val => val !== action.payload)]
            }

        case traitsTypes.setArtList:
            return {
                ...state,
                artList: action.payload
            }

        case traitsTypes.setFirstDoc:
            return {
                ...state,
                firstDoc: action.payload
            }
        case traitsTypes.setLastDoc:
            return {
                ...state,
                lastDoc: action.payload
            }
        case traitsTypes.setLimit:
            return {
                ...state,
                limit: action.payload
            }

        default:
            return state;
    }
}