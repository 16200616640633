import {combineReducers} from 'redux';
import { authReducer } from './authReducer/authReducer';
import { marketplaceReducer } from './marketplaceReducer/marketplaceReducer';
import { shoppingCartReducer } from './shoppingCartReducer/shopingCartReducer';
import { uiReducer } from './uiReducer/uiReducer';
import { walletProviderReducer } from './walletProviderReducer/walletProviderReducer';
import { checkoutReducer } from './checkoutReducer/checkoutReducer';
import { adminReducer } from './AdminReducer/adminReducer';
import { discountReducer } from './discountReducer/discountReducer';
import { traitsReducer } from './traitsReducer/traitsReducer';

export const rootReducer = combineReducers({
    ui: uiReducer,
    walletProvider: walletProviderReducer,
    auth: authReducer,
    shoppingCart: shoppingCartReducer,
    marketplace: marketplaceReducer,
    checkout: checkoutReducer,
    admin: adminReducer,
    discount: discountReducer,
    traits: traitsReducer,
})
