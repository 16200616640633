import {authTypes} from './authTypes';

const initialState = {
    uid: null,
    displayName: '',
    provider: null,
    email: null
}

export const authReducer = (state = initialState, action) => {
    switch(action.type) {

        // sets an authenticated user
        case authTypes.setUser:
            return {
                ...state,
                uid: action.payload.uid,
                displayName: action.payload.displayName,
                provider: action.payload.provider,
                email: action.payload.email
            }

        // stes and authenticated users
        case authTypes.signupUser:
            return {
                ...state,
                uid: action.payload.uid,
                displayName: action.payload.displayName,
                email: action.payload.email
            }

        // removes the authenticated user
        case authTypes.logOutUser:
            return {
                ...initialState
            }
        default:
            return state;
    }
}