import { Grid, Typography } from '@mui/material'
import React from 'react'
import { GenerateAdminCodes } from './components/GenerateAdminCodes'
import { AdminCodesList } from './components/AdminCodesList'

export const AdminCodes = () => {
  return (
    <Grid container direction={'column'} spacing={2} justifyContent={'center'} alignItems={'center'}>
      <Grid item>
         <Typography variant='h5' gutterBottom> Admin Codes</Typography>
         <Typography variant='body' gutterBottom>Specify, or generate the code with the discount in percentage and the limit of 1 of 1 to apply to. </Typography>
      </Grid>

      <Grid item>
        <GenerateAdminCodes/>
      </Grid>

      <Grid item>
        <AdminCodesList/>
      </Grid>
    </Grid>
  )
}
