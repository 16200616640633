import React, { useEffect } from 'react'
import { Twitter } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useDispatch } from 'react-redux'
import { isMobile } from 'web3modal'
import { authSignInTwitter, authStartSignUpUserMedia } from 'reducers/authReducer/authActions'
import { auth } from 'config/firebase'

export const TwitterAuth = () => {
  const dispatch = useDispatch();

  const loginTwitter = async () => {
    const result = await auth.getRedirectResult()
    if (result.credential) {
      if (result.credential.providerId === 'twitter.com')
        dispatch(authSignInTwitter(result))
    }
  }

  useEffect(() => {
    loginTwitter();
  }, [])
  

  const handleClick = () => {
    if (isMobile)
      dispatch( authStartSignUpUserMedia('twitter', false));
    else
      dispatch( authStartSignUpUserMedia('twitter', true));
  }
  
  return (
    <IconButton onClick={handleClick} size='large'>
        <Twitter sx={{ fontSize: 40 }}/>
    </IconButton>
  )
}
