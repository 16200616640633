import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Link, TableCell, TableRow } from "@mui/material";
import { db } from "config/firebase";

export const PhysicalRow = (props) => {
  const { oneOfOne, uid, orderId, email = "NA", tx, orderStatus } = props;
  const [tokenId, setTokenId] = useState([]);

  const getTokenId = async () => {
    const snapshot = await db
      .collection("drop1")
      .doc("blockchain")
      .collection("tx")
      .doc(tx)
      .get();

    if (snapshot.exists) {
      const data = snapshot.data();
      const { processedOneOfOnes } = data;
      setTokenId(processedOneOfOnes);
    }
  };

  useEffect(() => {
    if (tx) {
      getTokenId();
    }
  }, []);

  return (
    <TableRow
      key={`row${oneOfOne.artId}`}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {uid}
      </TableCell>
      <TableCell align="center">{email || "NA"}</TableCell>
      <TableCell align="center">{orderId}</TableCell>
      <TableCell align="center">{oneOfOne.artId}</TableCell>
      <TableCell align="center">{oneOfOne.size}</TableCell>
      <TableCell align="center">{oneOfOne.address}</TableCell>
      <TableCell align="center">
        <Link
          href={oneOfOne.aiFormula}
          rel="noopener noreferrer"
          target="_blank"
        >
          FORMULA
        </Link>
      </TableCell>
      <TableCell align="center">
        {oneOfOne.attributes.find((val) => val.trait_type === "Trim").value}
      </TableCell>
      <TableCell align="center">{oneOfOne.laces}</TableCell>
      <TableCell align="center">
        {tokenId.find((val) => val.artId === oneOfOne.artId)?.tokenId || "NA"}
      </TableCell>
      <TableCell align="center">
        <Link href={oneOfOne.static} rel="noopener noreferrer" target="_blank">
          IMAGE
        </Link>
      </TableCell>
      <TableCell align="center">{oneOfOne.status || "NOT STARTED"}</TableCell>
    </TableRow>
  );
};

PhysicalRow.propTypes = {
  uid: PropTypes.string.isRequired,
  email: PropTypes.string,
  orderId: PropTypes.string,
  oneOfOne: PropTypes.object.isRequired,
  tx: PropTypes.string,
  orderStatus: PropTypes.number,
};
