import { Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { db } from 'config/firebase';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { uiShowAlert } from 'reducers/uiReducer/uiActions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { logError } from 'utils/errors';

export const AdminCodesList = () => {
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [showRedeemed, setShowRedeemed] = useState(true)
    const dispatch = useDispatch();

    const getAdminCodes = async () => {
        setIsLoading(true);
        try {
            const query = db.collection('adminCodes');
            const result = await query.get();
            if (result.empty){
                setIsLoading(false);
                return;
            }
    
            for (const doc of result.docs){
                const data = doc.data();
                setList(list => [...list, data])
            }
        } catch (error) {
            logError('getAdminCodes', error, null)      
            dispatch(uiShowAlert('warning', 'Admin code list', 'We have been unable to load your admin code list. Check connection and try again.'));
        } finally {
            setIsLoading(false);
        }
    }
    
    useEffect(() => {
        getAdminCodes();
    }, [])

    const handleCopy = () => {
        dispatch(uiShowAlert('success', null, 'Copied to clipboard'));
    }

    const handleShowRedeemed = () => {
        setShowRedeemed(redeemed => !redeemed);
    }
    
  return (
    <div>
        {(isLoading) ? (
            <CircularProgress/>
        ) : (
            <Grid container direction={'column'} spacing={2} justifyContent="flex-start" alignItems="stretch">
                <Grid item>
                    <Grid container direction={'row'} spacing={2}  justifyContent="flex-end" alignItems="center">
                        <Grid item>
                            <Typography fontWeight={700}>
                                Redeemed: {list.filter(val => val.redeemed === true).length} / {list.length} 
                            </Typography>
                        </Grid>
                        <Grid item>
                            <FormGroup>
                                <FormControlLabel control={<Switch defaultChecked value={showRedeemed} onChange={handleShowRedeemed} color='info' />} label="Show Redeemed" />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Admin Code</TableCell>
                                    <TableCell>Discount</TableCell>
                                    <TableCell>Limit</TableCell>
                                    <TableCell align="center">Redeemed?</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.filter((!showRedeemed) ? (val => val.redeemed !== true) : (val => val === val)).map((val, index) => (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            {val.code}
                                            <CopyToClipboard text={val.code} onCopy={handleCopy}>
                                                <IconButton>
                                                    <ContentCopyIcon/>
                                                </IconButton>
                                            </CopyToClipboard>
                                        </TableCell>
                                        <TableCell align="center">
                                            {val.discount} %
                                        </TableCell>
                                        <TableCell align="center">
                                            {val.limit}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Checkbox  disabled checked={val.redeemed} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        )}
    </div>
  )
}
