import * as Web3 from 'web3'
import abi from 'utils/FastSneaks.json';

const FastSneaks = process.env.REACT_APP_FASTSNEAKS_ADDRESS;
const Boonji = process.env.REACT_APP_BOONJI_ADDRESS;
const SuperBoonji = process.env.REACT_APP_SUPERBOONJI_ADDRESS;

/**
 * returns the address in short format
 * @param {*} address 
 */
export const shortenAddress = (address) => {
    if (!address)
        return;
        
    return address.slice(0,5).concat('...').concat(address.slice(-5))
}

export const parseChainId = (chainId) => {
    switch (chainId){
        case '0x1': // ethereum main
            return 1;
        case '0x4': // ethereum rinkeby
            return 4;
        case '0x5': // ethereum goerli
            return 5;
        case '0x38': // Binance main
            return 38;
        case '0x61': // Binance testnet
            return 61;
        case '0x90': // Polygon main
            return 90;
        default:
            return 1;
    }
}

export const myReadOnlyProvider = () => {
    const network = process.env.REACT_APP_NETWORK;
    const infuraId = process.env.REACT_APP_INFURA_ID;
    const provider = new Web3.providers.HttpProvider(`https://${network}.infura.io/v3/${infuraId}`)
    return provider
}

export const toETH = (wei) => {
    return Web3.utils.fromWei(wei, 'ether');
}

export const myReadOnlyWeb3 = () => {
    const provider = myReadOnlyProvider();
    const web3 = new Web3(provider);
    return web3;
}

export const myReadOnlyContract = () => {
    const web3 = myReadOnlyWeb3();
    const nftContract = new web3.eth.Contract(abi.abi, FastSneaks);
    return nftContract;
}
export const myReadOnlyBoonjiContract = () => {
    const web3 = myReadOnlyWeb3();
    const nftContract = new web3.eth.Contract(abi.abi, Boonji);
    return nftContract;
}
export const myReadOnlySuperBoonjiContract = () => {
    const web3 = myReadOnlyWeb3();
    const nftContract = new web3.eth.Contract(abi.abi, SuperBoonji);
    return nftContract;
}

export const getMyContract = (provider) => {
    const nftContract = new provider.eth.Contract(abi.abi, FastSneaks);
    return nftContract;
}