export const traitsTypes = {
    setFormulas: '[traits] sets the formulas used to filter',
    setSkins: '[traits] sets the Skins used to filter',
    setLaces: '[traits] sets the laces used to filter',
    setTrims: '[traits] sets the trims used to filter',
    addSelectedFormula: '[traits] adds the formulas to be filtered',
    addSelectedSkin: '[traits] adds the skins to be filtered',
    addSelectedLace: '[traits] adds the laces to be filtered',
    addSelectedTrim: '[traits] adds the trims to be filtered',
    removeSelectedFormula: '[traits] remove the formulas to be filtered',
    removeSelectedSkin: '[traits] remove the skins to be filtered',
    removeSelectedLace: '[traits] remove the laces to be filtered',
    removeSelectedTrim: '[traits] remove the trims to be filtered',
    setArtList: '[traits] gets the art',
    setLastDoc: '[traits] sets the last doc we have',
    setFirstDoc: '[traits] sets the first doc we have',
    setLimit: '[traits] sets the search limit',
}