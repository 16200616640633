import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { FastShoesAnimation } from './three/FastShoesAnimation'
import { useLocation } from 'react-router-dom'

import { db } from 'config/firebase'
import { logError } from 'utils/errors'
import { useDispatch } from 'react-redux'
import { uiShowAlert } from 'reducers/uiReducer/uiActions'
import { CircularProgress } from '@mui/material'
import image from 'images/nft/nftBackground.jpeg'

export const Art = props => {
  const {manualArtId} = props;
  const location = useLocation();
  const [artId, setArtId] = useState();
  const [item, setItem] = useState();
  const [generate, setGenerate] = useState(false)
  const dispatch = useDispatch();
  const background = {type: 'image', url:image};

  const loadArt = async () => {
    try {
      const snapshot = await db.collection('drop1').doc('art').collection('metadata').doc(artId.toString()).get();
      if (snapshot.exists){
        const item = snapshot.data();
        setItem(item);
      }
    } catch ( error ) {
      logError('Loading art', error, 'na');
      dispatch(uiShowAlert('error', 'Loading Art', 'There was an error loading this art piece. Please refresh and try again.'));
    }
  }

  useEffect(() => {
    if (!manualArtId){
      const {pathname, search} = location;
      const id = pathname.replace('/art/', '');
      setArtId(Number(id));

      if (search){
        const isGenerate = search.replace('?generate=', '');
        if (isGenerate === 'true')
        setGenerate(true)
      }

    } else {
      setArtId(manualArtId);
    }

  }, [])

  useEffect(() => {
    if (artId){
      loadArt();
    }
  }, [artId])

  
  return (
    <div>
      {(item) ? (
        <div>
          <FastShoesAnimation 
            skin={item.skin} 
            formula={item.formula} 
            trim={item.trim} 
            laces={item.laces} 
            background={background}
            generateGif={generate}
            artId={artId}
          />
        </div>
      ) : (
        <div className='screen-center'>
          <CircularProgress/>
        </div>
      )}
    </div>
  )
}

Art.propTypes = {
  manualArtId: PropTypes.number
}
