import { adminTypes } from "./adminTypes";

const initialState = {
    isAdmin: false,
    batchMint: [],
    sales: [],
    users: [],
    money: [],
    inProgress: [],
    noMint: [],
    lastUpdated: null
};


export const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case adminTypes.setIsAdmin:
            return {
                ...state,
                isAdmin: action.payload
            }

        case adminTypes.setBatchMintList:
            return {
                ...state,
                batchMint: action.payload
            }

        case adminTypes.setMoneyList:
            return {
                ...state,
                money: action.payload
            }
        case adminTypes.setUsersList:
            return {
                ...state,
                users: action.payload
            }
        case adminTypes.setSalesList:
            return {
                ...state,
                sales: action.payload
            }
        case adminTypes.setInProgressList:
            return {
                ...state,
                inProgress: action.payload
            }
        
        case adminTypes.setNoMintList:
            return {
                ...state,
                noMint: action.payload
            }

        case adminTypes.setLastUpdated:
            return {
                ...state,
                lastUpdated: action.payload
            }
        default:
            return state;
    }
}