import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Badge, IconButton, Typography } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import { db, firebase } from 'config/firebase';
import { logError } from 'utils/errors';
import { CustomYesNoDialog } from 'common/CustomYesNoDialog';
import { useDispatch } from 'react-redux';
import { uiShowAlert } from 'reducers/uiReducer/uiActions';

export const AdminEmail = (props) => {
  const {orderId, templateId, dynamicTemplateData, uid, to, from, subject} = props;
  const [open, setOpen] = useState(false)
  const [yes, setYes] = useState(false);
  const [emailsSent, setEmailsSent] = useState(0)
  const dispatch = useDispatch();
  
  
  const getEmailsSent = async () => {
    try {
      const snapshot = await db.collection('users').doc(uid).collection('shoppingCart').doc(orderId).get();
      if (!snapshot.exists)
        return;
      
        const data = snapshot.data();
        setEmailsSent((data[templateId]) ? data[templateId] : 0)
    } catch (error) {
      logError('getEmailSent', error)
    }
  }

  const handleSendEmail = () => {
    setOpen(true);
  }

  const sendEmail = async () => {
    try {
      await db.collection('mail').doc().set({
        subject,
        from,
        to,
        dynamicTemplateData,
        templateId
      })

      await  db.collection('users').doc(uid).collection('shoppingCart').doc(orderId).set({[templateId]: firebase.firestore.FieldValue.increment(1) }, {merge: true})

      dispatch(uiShowAlert('success', 'Email sent', 'Email sent successfully.'))
    } catch (error) {
      logError('sendEmail', error)
      dispatch(uiShowAlert('error', 'Send Email', 'There was an error sending the email to the user. Please refresh and retry'));
    }
  }

  useEffect(() => {
    if (yes){
      sendEmail();
    }
  }, [yes])
  

  
  useEffect(() => {
    getEmailsSent();
  }, [])
  
  return (
    <Badge badgeContent={emailsSent} color="primary">
      <IconButton onClick={handleSendEmail}>
        <EmailIcon/>
      </IconButton>
      <CustomYesNoDialog 
        open={open} 
        setOpen={setOpen} 
        setYes={setYes} 
        title='Send email' 
        body={
          <span>
            <Typography variant='body1' gutterBottom textAlign={'center'}>Are you sure you want to send an email to this user?</Typography>
          </span>
        }
      />
    </Badge>

  )
}

AdminEmail.propTypes = {
    orderId: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    templateId: PropTypes.string.isRequired,
    dynamicTemplateData: PropTypes.object.isRequired
}
