import { CircularProgress, Grid, Typography } from '@mui/material'
import React from 'react'
import { InProgressCard } from './components/InProgressCard'
import { useSelector } from 'react-redux'
import { AdminEmail } from '../AdminNoMint/components/AdminEmail'

export const AdminInProgress = () => {
    const {inProgress} = useSelector(state => state.admin)
  return (
    <span>
        {(inProgress.length == 0) ? (
            <div className='screen-center'>
                <CircularProgress/>
            </div>
        ) : (
            <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={2}>
                <Grid item>
                    <Typography variant={'h6'} gutterBottom textAlign={'center'}>
                        List of users that selected items to buy but didn't completed the process by paying them
                    </Typography>
                </Grid>
                {inProgress.map((val, index) => (
                    <Grid item key={index} width={'100%'}>
                        <Grid container direction={'row'} >
                            <Grid item xs={(val.user.email) ? 11 : 12}>
                                <InProgressCard item={val}/>
                            </Grid>     
                            {(val.user.email) && (
                                <Grid item xs={1}>
                                    <AdminEmail 
                                        orderId={'current'} 
                                        templateId='d-9f1d457d47144d0eb25d0ca6072001d8' 
                                        to={val.user.email} 
                                        from={'support@fastsneaksproject.com'}
                                        subject={'You are almost there!'}
                                        uid={val.user.uid} 
                                        dynamicTemplateData={{oneOfOne: val.oneOfOnes[0].static}}
                                    />
                                </Grid>
                            )}   
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            
        )}
    </span>
  )
}
