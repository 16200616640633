export const shoppingCartTypes = {
    addOneOfOne: '[Shopping Cart] adds a new OneOfOne to the cart',
    removeOneOfOne: '[Shopping Cart] removes an existing OneOfOne from the cart',
    modifyOneOfOne: '[Shopping Cart] modifies an existing OneOfOne from the cart',
    clearAllItems: '[Shopping Cart] cleas the entire shopping cart',
    setShoppingCart: '[Shopping Cart] sets the shopping cart',
    addWearable: '[Shopping Cart] adds a new wearable to the cart',
    removeWearable: '[Shopping Cart] removes an existing wearable from the cart',
    modifyWearable: '[Shopping Cart] modifies an existing wearable from the cart',
    setStatus:  '[Shopping Cart] sets the status of the shopping cart',
    addHistoryCart:  '[Shopping Cart] adds an historic order in the history',
}