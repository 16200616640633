import React, {useEffect, useState} from 'react'
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';
import { Grid, Tabs } from '@mui/material';
import { BatchMint } from '../BatchMint';
import { AdminCodes } from '../DiscountCodes';
import { AdminStats } from '../AdminStats';
import { AdminArt } from '../AdminArt';
import { AdminInProgress } from '../AdminInProgress';
import Container from 'common/Container';
import { AdminNoMint } from '../AdminNoMint';
import { useDispatch, useSelector } from 'react-redux';
import { getLastUpdated } from 'reducers/AdminReducer/adminActions';
import moment from 'moment';
import { AdminShopify } from '../AdminShopify';
import { AdminPhysicals } from '../AdminPhysicals';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export const  AdminTabs = () => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const {lastUpdated} = useSelector(state => state.admin);

  useEffect(() => {
    dispatch(getLastUpdated());
  }, [])
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box marginRight={12}>
        <Typography textAlign={'end'}>
          Last updated: {(lastUpdated) ? moment.unix(lastUpdated.seconds).fromNow() : 'NA'}
        </Typography>
      </Box>
      <Box
        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%' }}
      >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: 'divider' }}
            >
              <Tab label="Statistics" {...a11yProps(0)} />
              <Tab label="Admin Codes" {...a11yProps(1)} />
              <Tab label="Art" {...a11yProps(2)} />
              <Tab label="Batch Mint" {...a11yProps(3)} />
              <Tab label="In Progress" {...a11yProps(4)} />
              <Tab label="No Mint" {...a11yProps(5)} />
              <Tab label="Shopify" {...a11yProps(6)} />
              <Tab label="Physical" {...a11yProps(7)} />
            </Tabs>
            
            <Container>
            <TabPanel value={value} index={0}>
              <AdminStats/>
            </TabPanel>


            <TabPanel value={value} index={1}>
              <AdminCodes/>
            </TabPanel>
            
            <TabPanel value={value} index={2}>
              <AdminArt/>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <BatchMint/>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <AdminInProgress/>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <AdminNoMint/>
            </TabPanel>
            <TabPanel value={value} index={6}>
              TBD{/* <AdminShopify/> */}
            </TabPanel>
            <TabPanel value={value} index={7}>
              <AdminPhysicals/>
            </TabPanel>
            </Container>
      </Box>
    </Box>
  );
}