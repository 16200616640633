import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { authStartLogOut } from 'reducers/authReducer/authActions';
import { uiShowAlert } from 'reducers/uiReducer/uiActions';
import { shortenAddress } from 'utils/blockchain';
import { useNavigate } from 'react-router-dom';
import { shortenEmail } from 'utils/email';

export const LoggedMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {uid, displayName, provider} = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginName, setLoginName] = useState(displayName)
  
  useEffect(() => {
    if (provider === 'web3'){
      setLoginName(shortenAddress(displayName))
    } else {
      if (displayName.length > 20){
        setLoginName(shortenEmail(displayName))
      } else {
        setLoginName(displayName)
      }
    }
    
  }, [displayName, provider])
  

  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDashboard = () => {
    navigate('/dashboard')
    handleClose()
  }

  
  const handleLogout = () => {
    try {
      dispatch (authStartLogOut())
      handleClose()
    } catch ( error ) {
      // if we are still logged in, we will let the user know
      if (!uid){
        dispatch(uiShowAlert('warning', 'Sign out', 'We have been unable to sign you out. Please refresh and try again.'))
      }    
    }
  }
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        variant={'contained'}
        color={'info'}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {loginName}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleDashboard}>Dashboard</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  )
}
