import { checkoutTypes } from "./checkoutTypes";

const initialState = {
    newAddress: null,
    addressList: [],
    selectedAddress: null,
    selectedWallet: null,
    walletList: [],
    total: {usd:0, eth:0},
    discount: 0,
    payed: false,
    discountCode: ''
};


export const checkoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case checkoutTypes.addNewAddress:
            return {
                ...state,
                newAddress: action.payload
            }

        case checkoutTypes.setAddressList:
            return {
                ...state,
                addressList: action.payload
            }

        case checkoutTypes.deleteAddress:
            return {
                ...state,
                addressList: [...state.addressList.filter(val => val.id !== action.payload)]
            }
        case checkoutTypes.setSelectedAddress:
            return {
                ...state,
                selectedAddress: action.payload
            }

        case checkoutTypes.setSelectedWallet:
            return{
                ...state,
                selectedWallet: action.payload
            }

        case checkoutTypes.setWalletList:
            return {
                ...state,
                walletList: action.payload
            }

        case checkoutTypes.deleteWallet:
            return {
                ...state,
                walletList: [...state.walletList.filter(val => val.wallet !== action.payload)]
            }

        case checkoutTypes.setTotal: 
        return {
            ...state,
            total: action.payload
        }

        case checkoutTypes.setPayed:
            return {
                ...state,
                payed: true
            }

        case checkoutTypes.setDiscountCode:
            return {
                ...state,
                discountCode: action.payload
            }

        case checkoutTypes.addWallet:
            return {
                ...state,
                walletList: (state.walletList.find(val => val.wallet === action.payload.wallet)) ? [...state.walletList.map(val => (val.wallet === action.payload.wallet) ? action.payload : val)] : [...state.walletList, action.payload]
            }

        case checkoutTypes.setDiscount:
            return {
                ...state,
                discount: action.payload
            }
        

        default:
            return state;
    }
}