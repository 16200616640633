export const uiTypes = {
    uiCloseAlert: '[UI] - Hides alert message',
    uiOpenAlert: '[UI] - Shows the alert message',

    uiOpenCreateSellOrderDialog: '[UI] - opens the create sell dialog',
    uiCloseCreateSellOrderDialog: '[UI] - close the create sell dialog',

    uiOpenCreateBuyOrderDialog: '[UI] - opens the create buy dialog',
    uiCloseCreateBuyOrderDialog: '[UI] - close the create buy dialog',

    uiOpenBackdrop: '[UI] - opens the backdrop',
    uiCloseBackdrop: '[UI] - close the backdrop',

    uiShowItemDetail: '[UI] - shows the item detail page',
    uiCloseItemDetail: '[UI] - closed the item detail page',

    uiSetMarketFilter: '[UI] sets which filter to use at the marketplace'
    
}