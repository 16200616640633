export const checkoutTypes = {
    addNewAddress: '[checkout] adds a new  address',
    setAddressList: '[checkout] gets the stored address list',
    deleteAddress: '[checkout] deletes an address from the address list',
    setSelectedAddress: '[checkout] sets the selected address',
    setSelectedWallet: '[checkout] sets the selected wallet',
    setWalletList: '[checkout] gets the stored wallet list',
    addWallet: '[checkout] adds or modifies a wallet',
    deleteWallet: '[checkout] deletes a wallet address from the wallet list',
    setTotal: '[checkout] sets the total',
    setPayed: '[checkout] marks the current order as payed',
    setDiscountCode: '[checkout] sets discount code',
    setDiscount: '[checkout] sets the discount',
}