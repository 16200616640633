import {shoppingCartTypes} from './shoppingCartTypes';

const initialState = {
    oneOfOnes: [],
    wearables: [],
    status: 0,
    history: []
};


export const shoppingCartReducer = (state = initialState, action) => {
    switch (action.type) {
        case shoppingCartTypes.addOneOfOne:
            return {
                ...state,
                oneOfOnes: [...state.oneOfOnes, action.payload]
            }
        
        case shoppingCartTypes.removeOneOfOne: 
            return {
                ...state,
                oneOfOnes: [...state.oneOfOnes.filter(item => item.artId !== action.payload.artId)]
            }

        case shoppingCartTypes.modifyOneOfOne:
            return {
                ...state,
                oneOfOnes: [...state.oneOfOnes.map(item => (item.artId === action.payload.artId) ? action.payload : item)]
            }

        case shoppingCartTypes.clearAllItems:
            return {
                ...state,
                oneOfOnes: [],
                wearables: [],
                status: 0
            };

        case shoppingCartTypes.setShoppingCart:
            return {
                ...state,
                oneOfOnes: [action.payload.oneOfOnes],
                wearables: [action.payload.wearables]
            }

        case shoppingCartTypes.addWearable:
            return {
                ...state,
                wearables: [...state.wearables, action.payload]
            }

        case shoppingCartTypes.removeWearable: 
            return {
                ...state,
                wearables: [...state.wearables.filter(item => item.id !== action.payload.id)]
            }

        case shoppingCartTypes.modifyWearable:
            return {
                ...state,
                wearables: [...state.wearables.map(item => (item.id === action.payload.id) ? action.payload : item)]
            }

        case shoppingCartTypes.setStatus:
            return {
                ...state,
                status: action.payload
            }

        case shoppingCartTypes.addHistoryCart:
            return {
                ...state,
                history: [...state.history, action.payload]
            }
        
        default:
            return state;
    }
}