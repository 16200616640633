import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import { BatchMintButton } from '../BatchMintButton';
import { getFormatedPrice } from 'utils/price';

export const BatchMintTable = () => {
    const {batchMint} = useSelector(state => state.admin);
  return (
    <Box>
        {(batchMint.length === 0) ? (
            <Box>
                <Typography variant='body' fontWeight={700}>
                    No records
                </Typography>
            </Box>
        ) : (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right">Total</TableCell>
                            <TableCell align="right">Discount</TableCell>
                            <TableCell align="right">One of Ones</TableCell>
                            <TableCell align="center">Wallet</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {batchMint.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            <BatchMintButton item={row}/>
                        </TableCell>
                            <TableCell align="right">${getFormatedPrice(row.total.usd)}</TableCell>
                            <TableCell align="right">{row.discount}</TableCell>
                            <TableCell align="center">{row.oneOfOnes}</TableCell>
                            <TableCell align="center">{row.selectedWallet.wallet}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )}
    </Box>
  )
}
