import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getBatchMint } from 'reducers/AdminReducer/adminActions';
import { BatchMintTable } from './components/BatchMintTable';

export const BatchMint = () => {
  const dispatch = useDispatch();
  
  
  const getBatchMintList =  async () => {
    dispatch(getBatchMint())
  }

  useEffect(() => {
    getBatchMintList();
  }, [])
  
  return (
    <Grid
      container
      direction={'column'}
      justifyItems={'center'}
      alignItems={'center'}
      spacing={2}
    >
      <Grid item>
        <Typography variant='h6'>
          Batch Mint
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='body'>
          The list of payed orders with credit card that haven't been minted yet.
        </Typography>
      </Grid>

      <Grid item>
        <BatchMintTable/>
      </Grid>


    </Grid>
  )
}
