import { db, firebase } from "config/firebase";
import moment from "moment";
import { uiShowAlert } from "reducers/uiReducer/uiActions";
import { logError } from "utils/errors";

const { shoppingCartTypes } = require("./shoppingCartTypes");

let unsubscribe;
export const getShoppingCart = () => {
    return async (dispatch, getState) => {
        const {uid} = getState().auth;
        try {
            const oneOfOneSnapshot = await db
                .collection('users')
                .doc(uid)
                .collection('shoppingCart')
                .doc('current')
                .collection('oneOfOnes')
                .get();

            if (!oneOfOneSnapshot.empty){
                oneOfOneSnapshot.docs.forEach(oneOfOne => {
                    dispatch(addOneOfOne(oneOfOne.data()));
                })
            }

            dispatch(subscribeToChanges());

            const wearablesSnapshot = await db
                .collection('users')
                .doc(uid)
                .collection('shoppingCart')
                .doc('current')
                .collection('wearables')
                .get();

            if (!wearablesSnapshot.empty){
                wearablesSnapshot.docs.forEach(wearable => {
                    dispatch(addWearable(wearable.data()));    
                })
            }
        } catch ( error ) {
            logError('getShoppingCart', error, null)
            dispatch(uiShowAlert('warning', 'Shopping cart', 'We have been unable to get your shopping cart. Please refresh and try again.'));
        }
    }
}

const firestoreSaveOneOfOne = async (uid, item) => {
    await db
        .collection('users')
        .doc(uid)
        .collection('shoppingCart')
        .doc('current')
        .collection('oneOfOnes')
        .doc(item.artId.toString())
        .set(item);
}

const firestoreDeleteOneOfOne = async (uid, item) => {
    await db
        .collection('users')
        .doc(uid)
        .collection('shoppingCart')
        .doc('current')
        .collection('oneOfOnes')
        .doc(item.artId.toString())
        .delete();
}

const firestoreSaveWearable = async (uid, item) => {
    const ref =  db
        .collection('users')
        .doc(uid)
        .collection('shoppingCart')
        .doc('current')
        .collection('wearables')
        .doc()
    const id = ref.id;
        await ref.set({...item, id});

        return id;
}

const firestoreDeleteWearable = async (uid, item) => {
    await db
        .collection('users')
        .doc(uid)
        .collection('shoppingCart')
        .doc('current')
        .collection('wearables')
        .doc(item.id)
        .delete();
    
}

export const addOneOfOneToCart =(item) => {
    return async (dispatch, getState) => {
        try {
            const {uid} = getState().auth;
            dispatch(addOneOfOne(item))
            await firestoreSaveOneOfOne(uid, item);
            dispatch(subscribeToChanges());
        } catch ( error ) {
            logError('addOneOfOneToCart', error, null)
            dispatch(uiShowAlert('warning', 'Shopping cart', 'We have been unable to save this last change on your shopping cart. Please refresh and try again.'));
        }
    }
}


export const removeOneOfOneFromCart = (item) => {
    return async (dispatch, getState) => {
        try {
            const {oneOfOnes, wearables} = getState().shoppingCart;
            const diference = oneOfOnes.length - wearables.length;
            const {uid} = getState().auth;
            dispatch(removeOneOfOne(item))
            await firestoreDeleteOneOfOne(uid, item);
            
            if (diference === 0){
                const last = wearables.slice(-1)[0];
                dispatch(removeWearableFromCart(last));
                dispatch(uiShowAlert('info', 'Wearable removed', 'We have removed your last chosen wearable automatically. Add a One of One to get more wearables.'))
            }
        } catch ( error ) {
            logError('removeOneOfOneFromCart', error, null)
            dispatch(uiShowAlert('warning', 'Shopping cart', 'We have been unable to save this last change on your shopping cart. Please refresh and try again.'));
        }
    }
}

export const addWearableToCart =(item) => {
    return async (dispatch, getState) => {
        try {
            const {uid} = getState().auth;
            const id = await firestoreSaveWearable(uid, item);
            dispatch(addWearable({item, id}))
        } catch ( error ) {
            logError('addWearableToCart', error, null)
            dispatch(uiShowAlert('warning', 'Shopping cart', 'We have been unable to save this last change on your shopping cart. Please refresh and try again.'));
        }
    }
}

export const removeWearableFromCart = (item) => {
    return async (dispatch, getState) => {
        try {
            const {uid} = getState().auth;
            await firestoreDeleteWearable(uid, item);
            dispatch(removeWearable(item))
        } catch ( error ) {
            logError('removeWearableFromCart', error, null)
            dispatch(uiShowAlert('warning', 'Shopping cart', 'We have been unable to save this last change on your shopping cart. Please refresh and try again.'));
        }
    }
}

const addOneOfOne = (item) => ({
    type: shoppingCartTypes.addOneOfOne,
    payload: item
})

const removeOneOfOne = (item) => ({
    type: shoppingCartTypes.removeOneOfOne,
    payload: item
})

const modifyOneOfOne = (item) => ({
    type: shoppingCartTypes.modifyOneOfOne,
    payload: item
})

const addWearable = (item) => ({
    type: shoppingCartTypes.addWearable,
    payload: item
})

const removeWearable = (item) => ({
    type: shoppingCartTypes.removeWearable,
    payload: item
})

const modifyWearable = (item) => ({
    type: shoppingCartTypes.modifyWearable,
    payload: item
})

const setShoppingCart = (cart) => ({
    type: shoppingCartTypes.setShoppingCart,
    payload: cart
})

export const resetShoppingCart = () => ({
    type: shoppingCartTypes.clearAllItems
})

export const setOneOfOneSize = (item) => {
    return async (dispatch, getState) => {
        try {
            const {uid} = getState().auth;
            
            if (!uid) 
                return;

            // in a transaction read and write
            db.runTransaction(async (transaction) => {
                const snapshot = await transaction.get(db.collection('users')
                .doc(uid)
                .collection('shoppingCart')
                .doc('current')
                .collection('oneOfOnes')
                .doc(item.artId.toString()))

                if (snapshot.exists){
                    transaction.set(
                        db.collection('users')
                        .doc(uid)
                        .collection('shoppingCart')
                        .doc('current')
                        .collection('oneOfOnes')
                        .doc(item.artId.toString()),
                        item, {merge:true})
                    
                    dispatch(modifyOneOfOne(item));
                }
            })
        } catch ( error ) {
            logError('setOneOfOneSize', error, null)
            dispatch(uiShowAlert('error', 'One of One', 'We have been unable to modify your latest change. Please refresh and try again.'));
        }
    }
}

export const setWearableSize = (item) => {
    return async (dispatch, getState) => {
        try {
            const {uid} = getState().auth;
            
            if (!uid) 
                return;

            await db
                .collection('users')
                .doc(uid)
                .collection('shoppingCart')
                .doc('current')
                .collection('wearables')
                .doc(item.id)
                .set(item, {merge:true})


            dispatch(modifyWearable(item));
        } catch ( error ) {
            logError('setWearableSize', error, null)
            dispatch(uiShowAlert('error', 'Wearable', 'We have been unable to modify your latest change. Please refresh and try again.'));
        }
    }
}

export const completeCryptoPayment = async (uid, tx) => {
        const oneOfOnes = [], wearables = [];
        
        const oneOfOnesSnapshot = await db.collection('users').doc(uid).collection('shoppingCart').doc('current').collection('oneOfOnes').get();
        for (const doc of oneOfOnesSnapshot.docs){
            const oneOfOne = doc.data();
            oneOfOnes.push(oneOfOne)
        }

        const wearablesSnapshot = await db.collection('users').doc(uid).collection('shoppingCart').doc('current').collection('wearables').get();
        for (const doc of wearablesSnapshot.docs){
            const wearable = doc.data();
            wearables.push(wearable)
        }

        return db.runTransaction(async (transaction) => {
            const currentRef = db.collection('users').doc(uid).collection('shoppingCart').doc('current');
            const snapshot = await transaction.get(currentRef)
            
            if (!snapshot.exists)
                throw new Error ('Something went wrong and we can do the checkout. Please refresh and retry.');

            
            const current = snapshot.data();
            
            const id = db.collection('users').doc(uid).collection('shoppingCart').doc().id;
            const now = moment().unix();
            const data = {...current, timestamp: now, id, status: 2, tx, paymentType: 'crypto'}
            
            transaction.set(db
                .collection('users')
                .doc(uid)
                .collection('shoppingCart')
                .doc(id),
                data)

            // crypto payment
            transaction.set(db.collection('drop1').doc('blockchain').collection('tx').doc(tx), {orderId: id, uid, processedOneOfOnes: []}, {merge:true})
            
            // we increase aquiredOneOfOnes value
            const userRef = db.collection('users').doc(uid);
            transaction.set(userRef, {aquiredOneOfOnes: firebase.firestore.FieldValue.increment(oneOfOnes.length)}, {merge:true})

            // if we have a referralCode then we redeem it
            const {discountCode} = current;
            if (discountCode){
                if (discountCode.type === 'admin'){
                    const codeRef = db.collection('adminCodes').doc(discountCode.code);
                    transaction.set(codeRef, {redeemed: true, orderId: id}, {merge:true})       
                }  else {
                    const codeRef = db.collection('referralCodes').doc(discountCode.code);
                    transaction.set(codeRef, {redeemed: true, orderId: id}, {merge:true})       
                }

            }

            for (const oneOfOne of oneOfOnes){
                transaction.set(db
                .collection('drop1')
                .doc('art')
                .collection('metadata')
                .doc(oneOfOne.artId.toString()),
                {available: false}, {merge:true})

                transaction.set(db
                .collection('users')
                .doc(uid)
                .collection('shoppingCart')
                .doc(id)
                .collection('oneOfOnes')
                .doc(oneOfOne.artId.toString())
                , oneOfOne)

                transaction.delete(db
                .collection('users')
                .doc(uid)
                .collection('shoppingCart')
                .doc('current')
                .collection('oneOfOnes')
                .doc(oneOfOne.artId.toString()))
            }
            
            for (const wearable of wearables){
                transaction.set( db
                .collection('users')
                .doc(uid)
                .collection('shoppingCart')
                .doc(id)
                .collection('wearables')
                .doc(wearable.id)
                ,wearable)

                transaction.delete(db
                .collection('users')
                .doc(uid)
                .collection('shoppingCart')
                .doc('current')
                .collection('wearables')
                .doc(wearable.id))
            }

            
            transaction.delete(db
                .collection('users')
                .doc(uid)
                .collection('shoppingCart')
                .doc('current'))
    })
}

const subscribeToChanges = () => {
    return async (dispatch, getState) => {
        try {
            const artIds = []
            
            const {oneOfOnes} = getState().shoppingCart;
            oneOfOnes.map(val => artIds.push(val.artId));

           if (unsubscribe)
               unsubscribe();

            if (artIds.length === 0)
                return;
   
           let query = db
               .collection('drop1')
               .doc('art')
               .collection('metadata')
               .where('artId', 'in', artIds)
           
            unsubscribe = query.onSnapshot((snapshot) => {
                snapshot.docChanges().forEach(async (change) => {
                    const changedOneOfOne = change.doc.data();

                    if (change.type === "modified" || change.type === 'added') {
                        const item = oneOfOnes.find(val => val.artId === changedOneOfOne.artId);
                        
                        if (item){
                            let modifiedItem;
                            if (changedOneOfOne.reserved)
                                modifiedItem = {...item, available: changedOneOfOne.available, reserved: changedOneOfOne.reserved}
                            else {
                                modifiedItem = {...item, available: changedOneOfOne.available}
                                delete modifiedItem.reserved;
                            }
                            dispatch(modifyOneOfOne(modifiedItem));
                            dispatch(setOneOfOneSize(modifiedItem));
                        }
                    }
                });
            })
        } catch ( error ){
            logError('subscribeToChanges', error, null)
        }
    }

    
}


export const initializeCheckout = () => {
    return async (dispatch, getState) => {
        try {
            const {uid} = getState().auth;
            const {oneOfOnes} = getState().shoppingCart;
            const {discount} = getState().discount;
            const {discountCode} = getState().checkout;

            if (oneOfOnes.some(val => val.reserved && val.reserved.by && val.reserved.by !== uid)){
                throw new Error(`At least a One of One has been reserved to someone else. You will have to wait in case it gets released. Please try again later.`)
            }

            if (oneOfOnes.some(val => !val.available)){
                throw new Error(`At least a One of One is no longer available. Please modify your shopping cart and try again.`)
            }

            // the user may no longer be subscribed to firestore, so we will retrieve again the one of ones in case the 
            // firebase function removed the reservation
            const oneOfOneSnapshot = await db
                .collection('users')
                .doc(uid)
                .collection('shoppingCart')
                .doc('current')
                .collection('oneOfOnes')
                .get();

            oneOfOneSnapshot.docs.forEach(doc => {
                const oneOfOne = doc.data();
                if (oneOfOne.reserved && oneOfOne.reserved.by !== uid){
                    throw new Error(`At least a One of One has been reserved to someone else. You will have to wait in case it gets released. Please try again later.`)
                }
    
                if (!oneOfOne.available){
                    throw new Error(`At least a One of One is no longer available. Please modify your shopping cart and try again.`)
                }
            })

            return db.runTransaction(async (transaction) => {  
                // we perform the read operations 
                let reservationTimestamp;
                for (const val of oneOfOnes){
                    const ref = db.collection('drop1').doc('art').collection('metadata').doc(val.artId.toString());
                    const snapshot = await transaction.get(ref)
                    const oneOfOne = snapshot.data();
                   
                    if (oneOfOne.reserved && oneOfOne.reserved.by && oneOfOne.reserved.by !== uid){
                        throw new Error(`At least a One of One has been reserved to someone else. You will have to wait in case it gets released. Please try again later.`)
                    }

                    if (oneOfOne.reserved && oneOfOne.reserved.by && oneOfOne.reserved.by === uid){
                        reservationTimestamp = oneOfOne.reserved.timestamp;
                    }
                };

                // we perform the write operations
                for (const val of oneOfOnes){
                    const ref = db.collection('drop1').doc('art').collection('metadata').doc(val.artId.toString());
                    transaction.update(ref, {reserved: {by: uid, timestamp: (reservationTimestamp) ? reservationTimestamp : moment().unix()}})
                }

                const statusRef = db.collection('users').doc(uid).collection('shoppingCart').doc('current');
                if (discountCode)
                    transaction.set(statusRef, {status: 1, timestamp: (reservationTimestamp) ? reservationTimestamp : moment().unix(), discountCode}, {merge:true})
                else
                    transaction.set(statusRef, {status: 1, timestamp: (reservationTimestamp) ? reservationTimestamp : moment().unix()}, {merge:true})

                dispatch(setShoppingCartStatus(1))
                return transaction;
            }) 
        } catch ( error ) {
            logError('initializeCheckout', error, null)
            dispatch(uiShowAlert('error', 'Checkout', error.message));
        }
    }
}

const setShoppingCartStatus = (status) => ({
    // 0: shopping cart in current document and still being created.
    // 1: Checkout has started either on crypto or fiat
    // 2: Payment done on crypto and shopping cart is history.
    // 3: Payment done on fiat and nft is not there yet. Shopping cart is history
    // 4: Payment done on fiat and nft is ready
    type: shoppingCartTypes.setStatus,
    payload: status
})

const addHistoryCart = (item) => ({
    type: shoppingCartTypes.addHistoryCart,
    payload: item
})

export const getHistoryOrders = () => {
    return async (dispatch, getState) => {
        try {
            const {uid} = getState().auth;
            const result = await db.collection('users').doc(uid).collection('shoppingCart').get();
            for (const doc of result.docs){
                if (doc.id !== 'current'){
                    const order = doc.data();
                    order.oneOfOnes = [];
                    order.wearables = [];

                    const oneOfOnes = await db.collection('users').doc(uid).collection('shoppingCart').doc(order.id).collection('oneOfOnes').get();

                    for (const oneOfOne of oneOfOnes.docs){
                        order.oneOfOnes.push(oneOfOne.data())
                    }

                    const wearables = await db.collection('users').doc(uid).collection('shoppingCart').doc(order.id).collection('wearables').get();
                    for (const wearable of wearables.docs){
                        order.wearables.push(wearable.data())
                    }

                    dispatch(addHistoryCart(order));
                }
            }
        } catch ( error ) {
            logError('getHistoryOrders', error, null)      
            dispatch(uiShowAlert('warning', 'Orders', 'There was an error loading your orders. Please refresh and retry.'))
        }
    }
}


