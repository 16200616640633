import { Card, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";
import { useSelector } from 'react-redux';

export const UserStats = () => {
  const {users} = useSelector(state => state.admin);
  const [data, setData] = useState([])
  const [accumulated, setAccumulated] = useState(0)
  
  useEffect(() => {
    if (users.length > 0)
      generateData()
  }, [users])

  const generateData = () => {
    let rows = []
    users.map(val => {
      rows.push([(val.email) ? val.email : val.address, val.aquiredOneOfOnes])
      setAccumulated((total) => total + val.aquiredOneOfOnes)
    })
    
    setData([
      ['day', 'total'],
      ...rows.sort(sortByAmount)
    ])
  }

  const sortByAmount = (a,b) => {
    if (a[1] > b[1])
      return -1
    else 
      return 1
  }

  const options = {
    title: "Purchases per user",
    legend: 'none',
  };
  return (
    <div>
      {(data.length == 0) ? (
        <div className='screen-center'>
          <CircularProgress/>
        </div>
      ) : (
        <Card elevation={2}>
          <Chart
            chartType="BarChart"
            width={'500px'}
            height={'500px'}
            data={data}
            options={options}
          />
          <Typography variant='h6' textTransform={'uppercase'} textAlign={'center'}>
              Total 1-of-1s purchased: {accumulated}
          </Typography>
        </Card>
      )}
    </div>
  )
}
