import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Grid, Typography } from '@mui/material';

import logo from 'images/logo/logo.png';
import { MyShoppingCart } from './components/MyShoppingCart';
import { UserMenu } from './components/UserMenu';

const Topbar = ({
  themeMode,
  themeToggler,
  setThemePalette,
  onSidebarOpen,
  paletteType,
}) => {
  const staticSiteUrl = process.env.REACT_APP_STATIC_SITE_URL;
  const isMarketOpen = (process.env.REACT_APP_IS_MARKET_OPEN == 'true') ? true : false;

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Box
            component="a"
            underline="none"
            href={staticSiteUrl}
            title="Fast Sneaks Project"
          >
            <img src={logo}  width={'70'} />
          </Box>
        </Grid>

        <Grid item>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={2}
          >
            <Grid item>
              <Link href={staticSiteUrl} underline='none'>
                <Typography variant='h6' color='textPrimary'>Home</Typography>
              </Link>
            </Grid>

            <Grid item>
              <Link href={'/'} underline='none'>
                <Typography variant='h6' color='textPrimary'>Shop</Typography>
              </Link>
            </Grid>

            <Grid item>
                <UserMenu/>
              </Grid>

            {/* <Grid item>
              <Link href='https://twitter.com/fastsneaksprjct' underline='none' target={'_blank'} rel='noopener'>
                <FontAwesomeIcon  icon={faTwitter} color='black' size='xl' />
              </Link>
            </Grid>

            <Grid item>
              <Link href='https://www.instagram.com/fastsneaksproject/' underline='none'  target={'_blank'} rel='noopener'>
                <FontAwesomeIcon  icon={faInstagram} color='black' size='xl' />
              </Link>
            </Grid>

            <Grid item>
              <Link href='https://discord.gg/dXzy5uj7ts' underline='none'  target={'_blank'} rel='noopener'>
                <FontAwesomeIcon  icon={faDiscord} color='black' size='xl'/>
              </Link>
            </Grid> */}


          </Grid>
        </Grid>

        <Grid item>
          {(isMarketOpen) && (
            <MyShoppingCart/>
          )}
        </Grid>
        
      </Grid>
    </div>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  setThemePalette: PropTypes.func.isRequired,
  paletteType: PropTypes.string.isRequired,
};

export default Topbar;
