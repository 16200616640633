import { Grid, Typography } from '@mui/material'
import Container from 'common/Container'
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { EmailPassAuth } from './components/EmailPassAuth'
import { FacebookAuth } from './components/FacebookAuth'
import { GoogleAuth } from './components/GoogleAuth'
import { TwitterAuth } from './components/TwitterAuth'
import { WalletAuth } from './components/WalletAuth'
import logo from 'images/logo/logo.png';

export const SignUp = () => {
    const {uid} = useSelector(state => state.auth);

    const googleEnabled = (process.env.REACT_APP_AUTH_GOOGLE_ENABLED === 'true' ? true : false); 
    const facebookEnabled = (process.env.REACT_APP_AUTH_FACEBOOK_ENABLED === 'true' ? true : false); 
    const emailEnabled = (process.env.REACT_APP_AUTH_EMAIL_ENABLED === 'true' ? true : false); 
    const twitterEnabled = (process.env.REACT_APP_AUTH_TWITTER_ENABLED === 'true' ? true : false); 
    const walletEnabled = (process.env.REACT_APP_AUTH_WALLET_ENABLED === 'true' ? true : false); 

  return (
    <Container>
        {(uid) ? (
            <Navigate  replace to={'/'}/>
        ) : (
            <Grid 
                container
                direction={'column'}
                justifyContent='center'
                alignItems={'center'}
                spacing={2}
            >
                <Grid item>
                    <img src={logo} width={'150px'} alt='Brendan Murphy'/>
                </Grid>
                <Grid item>
                    <Typography variant='h3' gutterBottom>
                        REGISTER
                    </Typography>
                </Grid>

                {
                    (walletEnabled) && (
                        <Grid item>
                            <WalletAuth/>
                        </Grid>
                    )
                }

                <Grid item>
                    <Typography variant='h5' gutterBottom textAlign={'center'}>
                                - OR -
                    </Typography>
                    <Grid container direction={'row'} spacing={4}>
                        {
                            (googleEnabled) && (
                                <Grid item>
                                    <GoogleAuth/>
                                </Grid>
                            )
                        }

                        {
                            (facebookEnabled) && (
                                <Grid item>
                                    <FacebookAuth/>
                                </Grid>
                            )
                        }

                        {
                            (twitterEnabled) && (
                                <Grid item>
                                    <TwitterAuth/>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
                {
                    (emailEnabled) && (
                        <Grid item>
                            <Typography variant='h5' gutterBottom textAlign={'center'}>
                                - OR -
                            </Typography>
                            <EmailPassAuth/>
                        </Grid>
                    )
                }

            </Grid>
               
        )}
    </Container>
  )
}
