import { db } from "config/firebase";
import { uiShowAlert } from "reducers/uiReducer/uiActions";
import { logError } from "utils/errors";
import { traitsTypes } from "./traitsTypes";

const traits = [
    'Formula',
    'Laces',
    'Skin',
    'Trim'
]

const sortBySkin = (a,b) => {
    const skin1 = a.trait_type.replace('skin', '');
    const skin2 = b.trait_type.replace('skin', '');

    if (isNaN(skin1) || isNaN(skin2))
        return 0

    if (Number(skin1) > Number(skin2))
        return 1
    else   
        return -1
}

const sortByFormula = (a,b) => {
    const formulaA = a.trait_type.split(' ')[0]
    const formulaB = b.trait_type.split(' ')[0]
    
    if (isNaN(formulaA) || isNaN(formulaB))
        return 0

    if (Number(formulaA) > Number(formulaB))
        return 1
    else   
        return -1
}

export const getTraits = () => {
    return async (dispatch) => {
        try {
            for (const trait of traits){
                const result = []
                const snapshot = await db.collection('drop1').doc('art').collection('traits').doc(trait).get();
                const data = snapshot.data();
                for (const [key, value] of Object.entries(data)) {
                    const val = {trait_type: key, usedIn: value}
                    result.push(val)
                    }
                switch(trait){
                    case 'Formula':
                        dispatch(setFormulas(result.sort(sortByFormula)))
                        break;
                    case 'Laces':
                        dispatch(setLaces(result))
                        break;
                    case 'Skin':
                        dispatch(setSkins(result.sort(sortBySkin)))
                        break;
                    case 'Trim':
                        dispatch(setTrims(result))
                        break;
                }

            }

        } catch (error) {
            await logError('getTraits', error)
        }
    }
}


const setFormulas = (formulas) => ({
    type: traitsTypes.setFormulas,
    payload: formulas
})
export const addSelectedFormula = (selectedFormula) => ({
    type: traitsTypes.addSelectedFormula,
    payload: selectedFormula
})
export const removeSelectedFormula = (selectedFormula) => ({
    type: traitsTypes.removeSelectedFormula,
    payload: selectedFormula
})

const setSkins = (skins) => ({
    type: traitsTypes.setSkins,
    payload: skins
})
export const addSelectedSkin = (selectedSkin) => ({
    type: traitsTypes.addSelectedSkin,
    payload: selectedSkin
})
export const removeSelectedSkin = (selectedSkin) => ({
    type: traitsTypes.removeSelectedSkin,
    payload: selectedSkin
})

const setLaces = (laces) => ({
    type: traitsTypes.setLaces,
    payload: laces
})
export const addSelectedLace = (selectedLace) => ({
    type: traitsTypes.addSelectedLace,
    payload: selectedLace
})
export const removeSelectedLace = (selectedLace) => ({
    type: traitsTypes.removeSelectedLace,
    payload: selectedLace
})
const setTrims = (trims) => ({
    type: traitsTypes.setTrims,
    payload: trims
})
export const addSelectedTrim = (selectedTrim) => ({
    type: traitsTypes.addSelectedTrim,
    payload: selectedTrim
})
export const removeSelectedTrim = (selectedTrim) => ({
    type: traitsTypes.removeSelectedTrim,
    payload: selectedTrim
})

const setArtList = (artList) => ({
    type: traitsTypes.setArtList,
    payload: artList
})


export const searchArt = (next = true, showEnabled = false) => {
    return async (dispatch, getState) => {
        try {
            const {selectedFormulas, selectedSkins, selectedLaces, selectedTrims, firstDoc, lastDoc, limit} = getState().traits;
            dispatch(setArtList([]))
            let query = db.collection('drop1').doc('art').collection('metadata')
            query = query.limit(limit)
            query = query.orderBy('artId', 'asc')
            query = query.where('available', '==', true)

            if (!showEnabled)
                query = query.where('enabled', '==', true)

            const filter = []
            if (selectedFormulas.length > 0){
                for (const val of selectedFormulas){
                    const obj = {trait_type: 'Formula', value: val}
                    filter.push(obj)
                }
            }
            if (selectedSkins.length > 0){
                for (const val of selectedSkins){
                    const obj = {trait_type: 'Skin', value: val}
                    filter.push(obj)
                }
            }
            if (selectedLaces.length > 0){
                for (const val of selectedLaces){
                    const obj = {trait_type: 'Laces', value: val}
                    filter.push(obj)
                }

            }
            if (selectedTrims.length > 0){
                for (const val of selectedTrims){
                    const obj = {trait_type: 'Trim', value: val}
                    filter.push(obj)
                }

            }
            if (filter.length > 0)
                query = query.where('attributes', 'array-contains-any', filter)

            if (lastDoc && next) 
                query = query.startAfter(lastDoc)
            
            if (firstDoc && !next) 
                query = query.endBefore(firstDoc)
            
                const result = []
            const snapshot = await query.get();
            if (snapshot.docs.length > 0){
                dispatch(setFirstDoc(snapshot.docs[0]))
                dispatch(setLastDoc(snapshot.docs[snapshot.docs.length-1])) 
            }  else {
                dispatch(setFirstDoc(null))
                dispatch(setLastDoc(null))
            }

            for (const doc of snapshot.docs){
                const data = doc.data();
                result.push(data)
            }
            dispatch(setArtList(result))
            
        } catch (error) {
            logError('searchArt', error)
        }
    }
}

const setFirstDoc = (doc) => ({
    type: traitsTypes.setFirstDoc,
    payload: doc
})
const setLastDoc = (doc) => ({
    type: traitsTypes.setLastDoc,
    payload: doc
})
const setLimit = (limit) => ({
    type: traitsTypes.setLimit,
    payload: limit
})