import { CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InProgressCard } from '../AdminInProgress/components/InProgressCard';
import { loadNoMint } from 'reducers/AdminReducer/adminActions';
import { AdminEmail } from './components/AdminEmail';

export const AdminNoMint = () => {
    const {noMint} = useSelector(state => state.admin);
    const dispatch = useDispatch()
    
    useEffect(() => {
      dispatch(loadNoMint())
    }, [])
    
  return (
    <span>
        <Typography variant='h6' gutterBottom textAlign={'center'}>
            List of customers who have already purchased one of ones, but didn't specify a minting wallet.
        </Typography>
        {(noMint.length == 0) ? (
            <div className='screen-center'>
                <CircularProgress/>
            </div>
        ) : (
            <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={2}>
                {noMint.map((val, index) => (
                    <Grid item key={index}>
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={11}>
                                <InProgressCard item={val}/>
                            </Grid>        
                            <Grid item xs={1}>
                                <AdminEmail 
                                    orderId={val.order.id} 
                                    templateId='d-90c0a88a156d45c78c7a1991b218917e' 
                                    to={val.order.selectedAddress.email} 
                                    from={'support@fastsneaksproject.com'}
                                    subject={'Add minting address to your order'}
                                    uid={val.user.uid} 
                                    dynamicTemplateData={{orderId: val.order.id}}
                                />
                            </Grid>        
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            
        )}
    </span>
  )
}
