import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSales, loadInProgress, verifiyIsAdmin } from 'reducers/AdminReducer/adminActions';
import { AdminTabs } from './components/AdminTabs';

export const Admin = () => {
    const {isAdmin} = useSelector(state => state.admin);
    const {uid} = useSelector(state => state.auth);

    const dispatch = useDispatch();
    
    useEffect(() => {
      if (uid)
        dispatch(verifiyIsAdmin());
    }, [uid])

    useEffect(() => {
      if (isAdmin){
        dispatch(loadInProgress());
        dispatch(getSales());
      }
    }, [isAdmin])
    
    
  return (
    <div>
        {(isAdmin) ? (
            <AdminTabs/>
        ) : (
            <div className='screen-center'>
                <CircularProgress/>
            </div>
            )
        }
    </div>
  )
}
